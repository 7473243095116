import * as React from "react";
import * as PropTypes from "prop-types";

import { useLocalization } from "@progress/kendo-react-intl";

import headerBg from "../assets/header-bg.png";

export const Header = (props) => {
  const { onButtonClick } = props;
  const localizationService = useLocalization();

  return (
    <header className="header" style={{ backgroundImage: `url(${headerBg})` }}>
      <div className="nav-container">
        <div className="menu-button">
          <span className={"k-icon k-i-menu"} onClick={onButtonClick} />
        </div>

        <div className="title">
          <h1>{localizationService.toLanguageString("custom.LincolnDTG")}</h1>
        </div>
        <div className="settings"></div>
        {/* <div className="settings">
                    <span>{localizationService.toLanguageString('custom.language')}</span>
                    <DropDownList
                        textField={'locale'}
                        dataItemKey={'localeId'}
                        data={locales}
                        value={currentLanguage}
                        onChange={onLanguageChange}
                    />
                </div>
                <Avatar type={'image'} shape={'circle'}>
                    {
                        hasImage ?
                            <img ref={imgRef} src={'#'} alt={'User Avatar'} /> :
                            <img src={userAvatar} alt="user-avatar"/>
                    }
                </Avatar>*/}
      </div>
    </header>
  );
};

Header.displayName = "Header";
Header.propTypes = {
  page: PropTypes.string,
  onButtonClick: PropTypes.func,
};
