import React, { useEffect, useState } from 'react';

import { useParams } from "react-router-dom";

import { useLocalization } from '@progress/kendo-react-intl';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import {
    emailValidator,
    requiredValidator,
    passwordMatchValidator,
    partsCodeValidator,
    address1Validator,
    cityValidator,
    stateValidator,
    zipValidator,
    phoneValidator,
    newPasswordValidator,
    // dealerCodeValidator
 } from '../validators';

import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import { useHistory } from 'react-router';
// import { DropDownList } from 'src/components/form/DropDownList';
import { Loader } from '@progress/kendo-react-indicators';
import { Dealer } from 'src/utils/classes';

type DealerParams = {
    dealer_id: string;
};

// const dealerTypes = [
//     {value: 'L', text:'Lincoln'},
//     {value: 'FL', text:'Ford/Lincoln'}
// ];

// const dealerRegions = [
//     {value:'CE', text:'Central Market Area'},
//     {value:'EMA', text:'East Market Area'},
//     {value:'GL', text:'Great Lakes Market Area'},
//     {value:'SE', text:'Southeast Market Area'},
//     {value:'W', text:'West Market Area'},
// ]

const DealerFormScreen = () => {
        const {...formValues} = React.useContext(AppContext);
        const localizationService = useLocalization();

        const { dtg } = useServices();
        let history = useHistory();
        const { dealer_id } = useParams<DealerParams>();

        const [ formError, setFormError ] = useState<string>();

        const [ processing, setProcessing ] = useState<boolean>(true);
        const [ dealer, setDealer ] = useState<Dealer>();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(() => {
            if (dealer_id !== '0') {
                dtg.getDealer(dealer_id).then((ret) => {
                    console.log('RET DEALER', ret);
                    
                    const {dealer} = (ret as DTGFetchResult).result;

                    // dealer.dealer_type = dealerTypes.find(x => x.value === dealer?.dealer_type);
                    // dealer.dealer_region = dealerRegions.find(x => x.value === dealer?.dealer_region);

                    setDealer(new Dealer(dealer));
                    setProcessing(false);
                });
            } else {
                setDealer({
                    dealer_id: 0,
                } as Dealer);
                setProcessing(false);
            }
        }, [dealer_id, dtg])

        const onSubmit = React.useCallback(
            async (dataItem) => {
                setProcessing(true);

                // dataItem.dealer_type = dataItem.dealer_type.value;
                // dataItem.dealer_region = dataItem.dealer_region.value;

                if (!dataItem.confirm_password) {
                    delete dataItem.password;
                }

                const call:any = await dtg.updateDealer(dataItem);
                if (call.result?.errors) {
                    console.log('[DealerFormScreen]', 'Update Dealer Error');
                    setFormError(call.result.errors.join('\n'));
                    setProcessing(false);
                } else {
                    console.log('[DealerFormScreen]', 'dealer updated');
                    history.push('/dealers');
                }
            },
            [dtg, history]
        );

        return (
            <div id="Dealer" className="dealer-page">
                <div className="card-container" style={{ maxWidth: 700 }}>
                    {
                        processing ? <div style={{textAlign:'center'}}><Loader type="infinite-spinner" size="large" /></div> :
                        <>
                            {
                                dealer?.dealer_id ?
                                <h1>Edit Retailer</h1>
                                :
                                <h1>Create Retailer</h1>
                            }
                            <div className="card-component">
                                <br/>
                                <h3>{dealer?.dealer_name}</h3>
                                <hr/>
                                <Form
                                    onSubmit={onSubmit}
                                    initialValues={{
                                        ...formValues,
                                        address2: '',
                                        ...dealer,
                                    }}
                                    render={(formRenderProps) => (
                                        <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                            { formError && <Error>{formError}</Error> }
                                            <Field
                                                id={'dealer_name'}
                                                name={'dealer_name'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.dealer_name', 'dealer_name')}
                                                validator={requiredValidator}
                                                component={Input}
                                                maxLength={50}
                                            />
                                            {/* <Field
                                                component={DropDownList}
                                                id={'dealer_type'}
                                                name={'dealer_type'}
                                                label={localizationService.toLanguageString('custom.dealer_type', 'dealer_type')}
                                                data={dealerTypes}
                                                dataItemKey="value"
                                                textField="text"
                                                required={true}
                                            /> */}
                                            <Field
                                                id={'parts_code'}
                                                name={'parts_code'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.sales_code', 'sales_code')}
                                                validator={partsCodeValidator}
                                                component={Input}
                                                minLength={5}
                                                maxLength={5}
                                            />
                                            {/* <Field
                                                id={'dealer_code'}
                                                name={'dealer_code'}
                                                label={localizationService.toLanguageString('custom.dealer_code', 'dealer_code')}
                                                component={Input}
                                                validator={dealerCodeValidator}
                                            /> */}
                                            <Field
                                                id={'address'}
                                                name={'address'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.address', 'address')}
                                                validator={address1Validator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'address2'}
                                                name={'address2'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.address2', 'address2')}
                                                component={Input}
                                            />
                                            <Field
                                                id={'city'}
                                                name={'city'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.city', 'city')}
                                                validator={cityValidator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'state'}
                                                name={'state'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.state', 'state')}
                                                validator={stateValidator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'zip'}
                                                name={'zip'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.zip', 'zip')}
                                                validator={zipValidator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'phone'}
                                                name={'phone'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.dealershipPhone', 'dealershipPhone')}
                                                validator={phoneValidator}
                                                component={Input}
                                            />
                                            {/* <Field
                                                component={DropDownList}
                                                id={'dealer_region'}
                                                name={'dealer_region'}
                                                label={localizationService.toLanguageString('custom.dealer_region', 'dealer_region')}
                                                data={dealerRegions}
                                                dataItemKey="value"
                                                textField="text"
                                                validator={requiredValidator}
                                                required={true}
                                            /> */}
                                            <Field
                                                id={'primary_contact'}
                                                name={'primary_contact'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.primary_contact', 'primary_contact')}
                                                validator={requiredValidator}
                                                component={Input}
                                                maxLength={50}
                                            />
                                            <Field
                                                id={'contact_phone'}
                                                name={'contact_phone'}
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.contact_phone', 'contact_phone')}
                                                validator={phoneValidator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'contact_email'}
                                                name={'contact_email'}
                                                type={'email'}
                                                autoComplete="username"
                                                placeholder={'e.g.: peter@gmail.com'}
                                                label={localizationService.toLanguageString('custom.contactEmail', 'Email')}
                                                validator={emailValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'password'}
                                                name={'password'}
                                                autoComplete="new-password"
                                                type={'password'}
                                                label={localizationService.toLanguageString('custom.password', 'Password')}
                                                validator={newPasswordValidator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'confirm_password'}
                                                name={'confirm_password'}
                                                type={'password'}
                                                autoComplete="new-password"
                                                // type={'input'}
                                                label={localizationService.toLanguageString('custom.confirmPassword', 'Confirm Password')}
                                                validator={passwordMatchValidator}
                                                component={Input}
                                            />
                                            <hr />
                                            <div className={'card-buttons'}>
                                                <Button
                                                    primary={true}
                                                    type={'submit'}
                                                    disabled={!formRenderProps.allowSubmit}
                                                >
                                                    {dealer?.dealer_id ? localizationService.toLanguageString('custom.update', 'update') : localizationService.toLanguageString('custom.save', 'save')}
                                                </Button>
                                            </div>
                                        </FormElement>
                                    )}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        );
}

export default DealerFormScreen;
