const emailRegex = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:`\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:`\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.(?!con))+[a-zA-Z]{2,}))$'
);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const address1Regex = new RegExp(/^\S[a-zA-Z0-9\s,'-/]*$/);
const partsCodeRegex = new RegExp(/^[0-9]+$/);
const cityRegex = new RegExp(/^\S+[a-zA-Z ,.'-]+$/);
const stateRegex = new RegExp(/^[a-zA-Z]{2}$/);
const zipRegex = new RegExp(
  /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/
);
const htmlRegex = new RegExp(/<[^>]*>/g);
const dollarRegex = new RegExp(/^\$?[0-9]+(\.[0-9][0-9])?$/);
const requiredMessage = "This field is required";

export const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "You have entered an invalid email address!";
export const partsCodeValidator = (value) =>
  value && partsCodeRegex.test(value) ? "" : "Please enter valid parts code.";
export const address1Validator = (value) =>
  value && address1Regex.test(value) ? "" : "Please enter valid address.";
export const cityValidator = (value) =>
  value && cityRegex.test(value) ? "" : "Please enter valid city.";
export const stateValidator = (value) =>
  value && stateRegex.test(value) ? "" : "Please enter valid state.";
export const zipValidator = (value) =>
  value && zipRegex.test(value) ? "" : "Please enter valid zip code.";
export const passwordValidator = (value, valueGetter) =>
  value && value.length > 5 ? "" : "Password must be at least six characters";
export const passwordMatchValidator = (value, valueGetter) =>
  value === valueGetter("password") ? "" : "Passwords must match";
export const newPasswordValidator = (value, valueGetter) =>
  (value && value.length > 5) || !value || !value.length
    ? ""
    : "Password must be at least six characters";
export const dollarValidator = (value, valueGetter) => {
  console.log('[dollarValidator]', value, dollarRegex.test(value));
  return value && dollarRegex.test(value) ? "" : "Please enter valid dollar amount.";
}
export const requiredValidator = (value) => (value ? "" : requiredMessage);
export const phoneValidator = (value) =>
  value && phoneRegex.test(value) ? "" : "Please enter valid phone number.";
export const biographyValidator = (value) =>
  value && value.replace(htmlRegex, "").length >= 50
  ? ""
  : "Biography must be at least 50 characters long.";
  export const ffsIDValidator = (value, valueGetter) => {
    return value && value.toString().length === 6 && !/\D/g.test(value) ? "" : "FFS ID must be six numbers.";
  }
export const dealerCodeValidator = (value) => {
  if (!value) {
    return;
  }
  return value && (value[0] === 'F' || value[0] === 'L') && (/^\d+$/.test(value.substring(1)) && value.substring(1).length === 5) ? "" : "Please enter a valid Dealer Code.";
}
