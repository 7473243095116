import React, { useEffect, useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { emailValidator, requiredValidator } from '../validators';

import { AppContext } from './../AppContext'
import { useStores } from 'src/stores';
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import { User } from 'src/utils/classes';

const LoginScreen = () => {
        const {...formValues} = React.useContext(AppContext);
        const localizationService = useLocalization();
        const history = useHistory();

        const [ loginError, setLoginError ] = useState<string>();
        const { login } = useStores();
        const { dtg } = useServices();

        let location = useLocation<Location>();

        let { from } = location.state as any || { from: { pathname: "/events" } };

        useEffect(() => {
            if (login.user) {
                console.log('[LoginScreen]', 'I think we are already logged in');
                history.replace('/events');
            }
        // eslint-disable-next-line
        }, [])

        const onSubmit = React.useCallback(
            async (dataItem) => {
                login.setLoginEmail(dataItem.email);
                const call:any = await dtg.doLogin(dataItem.email, dataItem.password);
                if (call.result.ERROR) {
                    console.log('[LoginScreen]', 'Login Error');
                    return setLoginError(call.result.message);
                }
                const thisUser = new User(call.result);
                console.log('[LoginScreen]', 'logged in, no 2fa required');
                login.setUserToken(thisUser.token)
                login.setUser(thisUser);
                history.replace(from);
            },
            [login, from, history, dtg]
        );

        return (
            <div id="Login" className="login-page">
                <div className="card-container" style={{ maxWidth: 700 }}>
                    <div className="card-component">
                    <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...formValues,
                                email: login.loginEmail,
                            }}
                            render={(formRenderProps) => (
                                <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                    { loginError && <Error>{loginError}</Error> }
                                    <Field
                                        id={'email'}
                                        name={'email'}
                                        type={'email'}
                                        placeholder={'e.g.: peter@gmail.com'}
                                        label={localizationService.toLanguageString('custom.email', 'Email')}
                                        validator={emailValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'password'}
                                        name={'password'}
                                        type={'password'}
                                        label={localizationService.toLanguageString('custom.password', 'Password')}
                                        validator={requiredValidator}
                                        component={Input}
                                    />
                                    <hr />
                                    <div className={'k-form-buttons'}>
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}
                                        >
                                            {localizationService.toLanguageString('custom.logIn', 'Log In')}
                                        </Button>
                                    </div>
                                </FormElement>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
}

export default LoginScreen;
