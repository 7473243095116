/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useParams } from "react-router-dom";

import { useLocalization } from '@progress/kendo-react-intl';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import {
    emailValidator,
    requiredValidator,
    passwordMatchValidator,
    address1Validator,
    cityValidator,
    stateValidator,
    zipValidator,
    phoneValidator,
    newPasswordValidator,
    partsCodeValidator
} from '../validators';
// import raw_timezones from 'src/stores/timezones.json';

import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import { MaskedTextBox } from 'src/components/form/MaskedTextBox';
import { useHistory } from 'react-router';
import moment from 'moment';
import capitalize from 'src/utils/capitalize';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from 'src/components/form/DropDownList';
import { DatePicker } from 'src/components/form/DatePicker';
import { NumericTextBox } from 'src/components/form/NumericTextBox';
import { TextArea } from '@progress/kendo-react-inputs';
import { useStores } from 'src/stores';
import { Dealer, DTGEvent, UserDetails } from 'src/utils/classes';

type EligibilityParams = {
    event_request_id: string;
};


// const dealerTypes = [
//     { value: 'L', text: 'Lincoln' },
//     { value: 'FL', text: 'Ford/Lincoln' }
// ];

// const dealerRegions = [
//     { value: 'CE', text: 'Central Market Area' },
//     { value: 'EMA', text: 'East Market Area' },
//     { value: 'GL', text: 'Great Lakes Market Area' },
//     { value: 'SE', text: 'Southeast Market Area' },
//     { value: 'W', text: 'West Market Area' },
// ]

const EventPlanningFormScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();

    const { dtg } = useServices();
    const { login } = useStores();
    let history = useHistory();
    const { event_request_id } = useParams<EligibilityParams>();

    const [dialogDisplayed, setDialogDisplayed] = useState<string>('none');
    const [formDisplayed, setFormDisplayed] = useState<string>('none');
    const [formError, setFormError] = useState<string>();

    const [planners, setPlanners] = useState<UserDetails[]>([]);

    const [thisEvent, setThisEvent] = useState<DTGEvent>();
    const [dealer, setDealer] = useState<Dealer>();
    const [charity, setCharity] = useState<Charity>();
    const [assignedPlanner, setAssignedPlanner] = useState<UserDetails>();
    // const [timeZones, setTimeZones] = useState<string[]>();

    // const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);

    // const onAdd = (event: UploadOnAddEvent) => {
    //     console.log("onAdd: ", event.affectedFiles);

    //     setFiles(event.newState);
    // };

    // const onRemove = (event: UploadOnRemoveEvent) => {
    //     console.log("onRemove: ", event.affectedFiles);

    //     setFiles(event.newState);
    // };

    // const onProgress = (event: UploadOnProgressEvent) => {
    //     console.log("onProgress: ", event.affectedFiles);

    //     setFiles(event.newState);
    // };

    // const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    //     console.log("onStatusChange: ", event.affectedFiles);

    //     setFiles(event.newState);
    // };

    // TODO: use the season not hard-coded dates
    const programStart = moment();
    const programEnd = moment('2022-11-30');
    let interim = programStart.clone();
    const months: string[] = [];
    while (programEnd > interim || interim.format('M') === programEnd.format('M')) {
        months.push(interim.format('MMMM'));
        interim.add(1, 'month');
    }

    const getRequestData = () => {
        // setTimeZones(raw_timezones);
        dtg.getPlanners().then((plannersResult) => {
            const plannersResultData = (plannersResult as DTGFetchResult).result as UserDetails[]
            setPlanners(plannersResultData);
            dtg.getEventRequest(event_request_id).then((ret) => {
                const {
                    event,
                    dealer,
                    charity,
                } = (ret as DTGFetchResult).result;

                const { assigned_planner } = JSON.parse(event.eligibility_custom_data || '{}');

                // dealer.dealer_type = dealerTypes.find(x => x.value === dealer?.dealer_type);
                // dealer.dealer_region = dealerRegions.find(x => x.value === dealer?.dealer_region);

                charity.address_2 = charity.address_2 || '';

                setAssignedPlanner(plannersResultData.find(x => x.user_id === assigned_planner));
                setThisEvent(new DTGEvent(event));
                setDealer(new Dealer(dealer));
                setCharity(charity as Charity);
            });
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getRequestData, []);

    const onSubmitDealer = React.useCallback(
        async (dataItem) => {
            setFormDisplayed('none');

            // dataItem.dealer_type = dataItem.dealer_type.value;
            // dataItem.dealer_region = dataItem.dealer_region.value;

            if (!dataItem.confirm_password) {
                delete dataItem.password;
            }

            const call: any = await dtg.updateDealer(dataItem);
            if (call.result?.errors) {
                console.log('[EventPlanningFormScreen]', 'Update Dealer Error');
                setFormError(call.result.errors.join('\n'));
            } else {
                console.log('[EventPlanningFormScreen]', 'dealer updated');
                history.push('/events');
            }
        },
        [dtg, history]
    );

    const onSubmitCharity = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            setFormDisplayed('none');

            const call: any = await dtg.updateCharity(dataItem);
            if (call.result.errors) {
                console.log('[EventPlanningFormScreen]', 'Update Charity Error');
                setFormError(call.result.errors.join('\n'));
            } else {
                console.log('[EventPlanningFormScreen]', 'charity updated');
                history.push('/events');
            }
        },
        [dtg, history]
    );

    const onSubmitEligibility = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            setFormDisplayed('none');

            let customData = JSON.parse(thisEvent!.eligibility_custom_data || '{}');
            customData = {
                ...customData,
                requested_month: dataItem.requested_month,
                requested_date: dataItem.requested_date,
                assigned_planner: dataItem.assigned_planner?.user_id,
            }

            dataItem.eligibility_custom_data = JSON.stringify(customData);

            const call: any = await dtg.updateEligibility(new DTGEvent(dataItem));
            if (call.result.errors) {
                console.log('[EventPlanningFormScreen]', 'Update Event Request Error');
                setFormError(call.result.errors.join('\n'));
            } else {
                console.log('[EventPlanningFormScreen]', 'Event Request updated');
                history.push('/events');
            }
        },
        [dtg, history, thisEvent]
    );

    const onSubmitPlanner = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            dtg.setEligibleEventRequest(thisEvent!.event_request_id!, dataItem.assigned_planner.user_id).then(res => {
                setDialogDisplayed('none');
                history.push('/events');
            });
        },
        [dtg, history, thisEvent]
    );

    const rejectEligibility = () => {
        dtg.setIneligibleEventRequest(thisEvent!.event_request_id!).then(res => {
            setDialogDisplayed('none');
            history.push('/events');
        });
    };

    // const filterTimeZones = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    //     return filterBy(raw_timezones, filter);
    // };

    // const filterTZ = (event: DropDownListFilterChangeEvent) => {
    //     setTimeZones(filterTimeZones(event.filter));
    // };

    // const formatRange = (start?: Date, end?: Date) => {
    //     let range = '';
    //     if (start) {
    //         range += moment(start).format('h:mm a');
    //     }
    //     if (start && end) {
    //         range += ' - ';
    //     }
    //     if (end) {
    //         range += moment(end).format('h:mm a');
    //     }

    //     return range;
    // }

    // const { centuryParty, file_id, file_name } = JSON.parse(thisEvent?.event_custom_data || '{}');

    // const onSubmitPlan = React.useCallback(
    //     async (dataItem) => {
    //         console.log('dataItem!!!!!!!!', dataItem);
    //         setFormDisplayed('none');

    //         let customData = JSON.parse(thisEvent!.eligibility_custom_data || '{}');
    //         customData = {
    //             ...customData,
    //             requested_month: dataItem.requested_month,
    //             requested_date: dataItem.requested_date,
    //         }

    //         dataItem.eligibility_custom_data = JSON.stringify(customData);

    //         let eventCustomData = JSON.parse(thisEvent!.event_custom_data || '{}');
    //         eventCustomData = {
    //             ...eventCustomData,
    //             centuryParty: dataItem.event_type?.value,
    //         }
    //         dataItem.event_custom_data = JSON.stringify(eventCustomData);
    //         dataItem.event_type = dataItem.event_type?.value;

    //         const [selectedFile] = files;
    //         const rawFile = selectedFile && selectedFile.getRawFile && selectedFile.getRawFile();
    //         // if (rawFile) {
    //         //     dataItem.event_plan_file = rawFile;
    //         // }

    //         const call: any = await dtg.saveEventPlan(new DTGEvent(dataItem), rawFile);
    //         if (call.result.errors) {
    //             console.log('[EventPlanningFormScreen]', 'Update Event Request Error');
    //             setFormError(call.result.errors.join('\n'));
    //         } else {
    //             console.log('[EventPlanningFormScreen]', 'Event Request updated');
    //             history.push('/events');
    //         }
    //     },
    //     [dtg, files, history, thisEvent]
    // );

    // const doDenyPlan = () => {
    //     setDialogDisplayed('denyPlan');
    // };

    const onDenyPlan = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            dtg.denyPlan(thisEvent!.event_request_id!, dataItem.deny_message).then(res => {
                setDialogDisplayed('none');
                history.push('/events');
            });
        },
        [dtg, history, thisEvent]
    );

    // const statusesThatCanBeRejected = [
    //     'new', // needs eligibility confirmation
    //     'eligible', // is eligible, no event plans yet
    //     'planning', // in planning state
    //     'revise', // event plan denied
    //     'review', // submitted for review
    // ];

    // const doRejectPlan = () => {
    //     setDialogDisplayed('rejectPlan');
    // };

    const onRejectPlan = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            dtg.rejectPlan(thisEvent!.event_request_id!, dataItem.reject_message).then(res => {
                setDialogDisplayed('none');
                history.push('/events');
            });
        },
        [dtg, history, thisEvent]
    );

    const onSubmitFinalApproval = React.useCallback(
        async (dataItem) => {
            const call: any = await dtg.submitFinalApproval(thisEvent?.event_request_id);
            if (call.result.errors) {
                console.log('[EventPlanningFormScreen]', 'Event Approved Error');
                setFormError(call.result.errors.join('\n'));
            } else {
                console.log('[EventPlanningFormScreen]', 'Event Approved');
                history.push('/events');
            }
        },
        [dtg, history, thisEvent]
    );

    const attendanceRef: any = React.useRef(null);
    const attendanceRef2: any = React.useRef(null);
    const dateRef: any = React.useRef(null);
    const requestedDateRef: any = React.useRef(null);
    const startTimeRef: any = React.useRef(null);
    const endTimeRef: any = React.useRef(null);

    const handleOnWheel = (event: any) => {
        event.stopPropagation();
    };

    React.useEffect(() => {
        attendanceRef?.current?.element.addEventListener('wheel', handleOnWheel);
        attendanceRef2?.current?.element.addEventListener('wheel', handleOnWheel);
        dateRef?.current?.element.addEventListener('wheel', handleOnWheel);
        requestedDateRef?.current?.element.addEventListener('wheel', handleOnWheel);
        startTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);
        endTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);

        return () => {
            attendanceRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            attendanceRef2?.current?.element.removeEventListener('wheel', handleOnWheel);
            dateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            requestedDateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            startTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            endTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
        };
    });

    return (
        <div id="Eligibility" className="eligibility-page">
            {
                dialogDisplayed === 'approveEligibility' &&
                <Dialog>
                    <h3>Assign a Planner</h3>
                    <Form
                        onSubmit={onSubmitPlanner}
                        initialValues={{
                            ...formValues,
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true} style={{ width: 250 }}>
                                {formError && <Error>{formError}</Error>}
                                <Field
                                    component={DropDownList}
                                    id={'assigned_planner'}
                                    name={'assigned_planner'}
                                    label={localizationService.toLanguageString('custom.assigned_planner', 'assigned_planner')}
                                    data={planners}
                                    dataItemKey="user_id"
                                    textField="display_name"
                                    required={true}
                                />
                                <br />
                                <DialogActionsBar>
                                    <Button
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        {localizationService.toLanguageString('custom.save', 'save')}
                                    </Button>
                                </DialogActionsBar>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }
            {
                dialogDisplayed === 'denyPlan' &&
                <Dialog>
                    <h3>Deny Plan</h3>
                    <Form
                        onSubmit={onDenyPlan}
                        initialValues={{
                            ...formValues,
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true}>
                                <p>Please enter a message for the Retailer.</p>
                                {formError && <Error>{formError}</Error>}
                                <Field
                                    component={TextArea}
                                    id={'deny_message'}
                                    name={'deny_message'}
                                    label={localizationService.toLanguageString('custom.deny_plan_reason', 'deny_plan_reason')}
                                    required={true}
                                />
                                <DialogActionsBar>
                                    <Button
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        {localizationService.toLanguageString('custom.deny_plan', 'deny_plan')}
                                    </Button>
                                    <Button onClick={() => setDialogDisplayed('none')}>
                                        {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                    </Button>
                                </DialogActionsBar>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }
            {
                dialogDisplayed === 'rejectPlan' &&
                <Dialog>
                    <h3>Reject Plan</h3>
                    <Form
                        onSubmit={onRejectPlan}
                        initialValues={{
                            ...formValues,
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true}>
                                <p>Please enter a message for the Retailer explaining why this event is permanently rejected.</p>
                                {formError && <Error>{formError}</Error>}
                                <Field
                                    component={TextArea}
                                    id={'reject_message'}
                                    name={'reject_message'}
                                    label={localizationService.toLanguageString('custom.reject_plan_reason', 'reject_plan_reason')}
                                    required={true}
                                />
                                <DialogActionsBar>
                                    <Button
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        {localizationService.toLanguageString('custom.reject_plan', 'reject_plan')}
                                    </Button>
                                    <Button onClick={() => setDialogDisplayed('none')}>
                                        {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                    </Button>
                                </DialogActionsBar>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }
            {
                dialogDisplayed === 'approvePlan' &&
                <Dialog>
                    <h3>Approve Event</h3>
                    <Form
                        onSubmit={onSubmitFinalApproval}
                        ignoreModified={true}
                        initialValues={{
                            ...formValues,
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true}>
                                <p>Are you sure you want to approve this event? The dealer will be notified immediately.</p>
                                {formError && <Error>{formError}</Error>}
                                <DialogActionsBar>
                                    <Button
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        {localizationService.toLanguageString('custom.approve_event', 'approve_event')}
                                    </Button>
                                    &nbsp;
                                    <Button onClick={() => setDialogDisplayed('none')}>
                                        {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                    </Button>
                                </DialogActionsBar>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }
            {
                dialogDisplayed === 'none' &&
                <div className="card-container" style={{ maxWidth: 700 }}>
                    <h1 className="card-title">{localizationService.toLanguageString('custom.event_request', 'event_request')}</h1>

                    <div className="card-component">
                        <br />
                        <h3>
                            Status: {capitalize(thisEvent?.status)}
                            {
                                // Eligibility buttons
                                login.user?.credentials.hasPermission('admin') && thisEvent?.status === 'new' &&
                                <p>
                                    <Button onClick={() => setDialogDisplayed('approvePlan')}>Approve Eligibility</Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button onClick={rejectEligibility}>Reject Eligibility</Button>
                                </p>
                            }
                        </h3>
                        <hr />

                        {
                            formDisplayed === 'none' &&
                            <>
                                <h3>Retailer <Button onClick={() => setFormDisplayed('dealer')}>View/Edit Retailer</Button></h3>
                                <p>
                                    Name: {dealer?.dealer_name}<br />
                                    Location: {dealer?.city}, {dealer?.state}<br />
                                    Sales Code: {dealer?.parts_code}
                                </p>
                            </>
                        }
                        {
                            formDisplayed === 'dealer' &&
                            <Form
                                onSubmit={onSubmitDealer}
                                initialValues={{
                                    ...formValues,
                                    ...dealer,
                                }}
                                render={(formRenderProps) => (
                                    <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                        <h3>Edit Retailer</h3>
                                        {formError && <Error>{formError}</Error>}
                                        <Field
                                            id={'dealer_name'}
                                            name={'dealer_name'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.dealer_name', 'dealer_name')}
                                            validator={requiredValidator}
                                            component={Input}
                                            maxLength={50}
                                        />
                                        <Field
                                            id={'parts_code'}
                                            name={'parts_code'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.sales_code', 'sales_code')}
                                            validator={partsCodeValidator}
                                            component={Input}
                                            minLength={5}
                                            maxLength={5}
                                        />
                                        <Field
                                            id={'address'}
                                            name={'address'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.address', 'address')}
                                            validator={address1Validator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'address2'}
                                            name={'address2'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.address2', 'address2')}
                                            component={Input}
                                        />
                                        <Field
                                            id={'city'}
                                            name={'city'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.city', 'city')}
                                            validator={cityValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'state'}
                                            name={'state'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.state', 'state')}
                                            validator={stateValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'zip'}
                                            name={'zip'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.zip', 'zip')}
                                            validator={zipValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'phone'}
                                            name={'phone'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.dealershipPhone', 'dealershipPhone')}
                                            validator={phoneValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'primary_contact'}
                                            name={'primary_contact'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.primary_contact', 'primary_contact')}
                                            validator={requiredValidator}
                                            component={Input}
                                            maxLength={50}
                                        />
                                        <Field
                                            id={'contact_phone'}
                                            name={'contact_phone'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.contact_phone', 'contact_phone')}
                                            validator={phoneValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'contact_email'}
                                            name={'contact_email'}
                                            type={'email'}
                                            autoComplete="username"
                                            placeholder={'e.g.: peter@gmail.com'}
                                            label={localizationService.toLanguageString('custom.contactEmail', 'Email')}
                                            validator={emailValidator}
                                            required={true}
                                            component={Input}
                                        />
                                        <Field
                                            id={'password'}
                                            name={'password'}
                                            autoComplete="new-password"
                                            type={'password'}
                                            label={localizationService.toLanguageString('custom.password', 'Password')}
                                            validator={newPasswordValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'confirm_password'}
                                            name={'confirm_password'}
                                            type={'password'}
                                            autoComplete="new-password"
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.confirmPassword', 'Confirm Password')}
                                            validator={passwordMatchValidator}
                                            component={Input}
                                        />
                                        <hr />
                                        <div className={'card-buttons'}>
                                            <Button
                                                type={'button'}
                                                onClick={() => setFormDisplayed('none')}
                                            >
                                                {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                            </Button>

                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {localizationService.toLanguageString('custom.update', 'update')}
                                            </Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        }

                        {
                            formDisplayed === 'none' &&
                            <>
                                <h3>Charity <Button onClick={() => setFormDisplayed('charity')}>View/Edit Charity</Button></h3>
                                <p>
                                    Name: {charity?.name}<br />
                                    Tax ID: {charity?.tax_id}
                                </p>
                            </>
                        }
                        {
                            formDisplayed === 'charity' &&
                            <Form
                                onSubmit={onSubmitCharity}
                                initialValues={{
                                    ...formValues,
                                    ...charity,
                                }}
                                render={(formRenderProps) => (
                                    <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                        <h3>Edit Charity</h3>
                                        {formError && <Error>{formError}</Error>}
                                        <Field
                                            id={'name'}
                                            name={'name'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.charity_name', 'charity_name')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        {/* <Field
                                            id={'address_1'}
                                            name={'address_1'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.address_1', 'address_1')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'address_2'}
                                            name={'address_2'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.address_2', 'address_2')}
                                            component={Input}
                                        />
                                        <Field
                                            id={'city'}
                                            name={'city'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.city', 'city')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'state'}
                                            name={'state'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.state', 'state')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'postal_code'}
                                            name={'postal_code'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.zip', 'zip')}
                                            validator={requiredValidator}
                                            component={Input}
                                        /> */}
                                        <Field
                                            id={'contact_first_name'}
                                            name={'contact_first_name'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.contact_first_name', 'contact_first_name')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'contact_last_name'}
                                            name={'contact_last_name'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.contact_last_name', 'contact_last_name')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'contact_phone_number'}
                                            name={'contact_phone_number'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.contact_phone_number', 'contact_phone_number')}
                                            required={true}
                                            validator={phoneValidator}
                                            mask="000-000-0000"
                                            component={MaskedTextBox}
                                        />
                                        <Field
                                            id={'contact_email'}
                                            name={'contact_email'}
                                            type={'email'}
                                            placeholder={'e.g.: peter@gmail.com'}
                                            label={localizationService.toLanguageString('custom.contactEmail', 'Email')}
                                            validator={emailValidator}
                                            required={true}
                                            component={Input}
                                        />
                                        {/* <Field
                                            id={'description'}
                                            name={'description'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.description', 'description')}
                                            validator={requiredValidator}
                                            component={Input}
                                        /> */}
                                        <Field
                                            id={'website'}
                                            name={'website'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.website', 'website')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'tax_id'}
                                            name={'tax_id'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.tax_id', 'tax_id')}
                                            validator={requiredValidator}
                                            mask="00-0000000"
                                            component={MaskedTextBox}
                                        />
                                        <hr />
                                        <div className={'card-buttons'}>
                                            <Button
                                                type={'button'}
                                                onClick={() => setFormDisplayed('none')}
                                            >
                                                {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                            </Button>

                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {localizationService.toLanguageString('custom.update', 'update')}
                                            </Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        }

                        {
                            (formDisplayed === 'none' && thisEvent?.event_request_id) &&
                            <>
                                <h3>Eligibility Request</h3>
                                {/* <Button onClick={() => setFormDisplayed('eventRequest')}>View/Edit Request</Button> */}
                                <p>
                                    Name: {thisEvent?.event_name}<br />
                                    Charity: {thisEvent?.charity_partner}<br />
                                    Requested Date: {thisEvent?.requested_date_formatted}<br />
                                    Funding Purpose: {thisEvent?.funding_purpose}<br />
                                </p>
                            </>
                        }

                        {formDisplayed === 'eventRequest' &&
                            <Form
                                onSubmit={onSubmitEligibility}
                                initialValues={{
                                    ...formValues,
                                    ...thisEvent,
                                    assigned_planner: assignedPlanner,
                                    requested_month: thisEvent?.requested_month,
                                    requested_date: thisEvent?.requested_date,
                                }}
                                render={(formRenderProps) => (
                                    <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                        <h3>Edit Eligibility Request</h3>
                                        {formError && <Error>{formError}</Error>}
                                        <Field
                                            component={DropDownList}
                                            id={'assigned_planner'}
                                            name={'assigned_planner'}
                                            label={localizationService.toLanguageString('custom.assigned_planner', 'assigned_planner')}
                                            data={planners}
                                            dataItemKey="user_id"
                                            textField="display_name"
                                            required={true}
                                        />
                                        <Field
                                            id={'attendance'}
                                            name={'attendance'}
                                            childRef={attendanceRef}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.attendance', 'attendance')}
                                            validator={requiredValidator}
                                            component={NumericTextBox}
                                        />
                                        <Field
                                            id={'requested_month'}
                                            name={'requested_month'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.requested_month', 'requested_month')}
                                            validator={requiredValidator}
                                            data={months}
                                            component={DropDownList}
                                        />
                                        <Field
                                            id={'requested_date'}
                                            name={'requested_date'}
                                            childRef={requestedDateRef}
                                            label={localizationService.toLanguageString('custom.requested_date', 'requested_date')}
                                            format="MM/dd/yyyy"
                                            // no minimum for admins
                                            // min={moment.max(moment('2022-03-01'), moment().add(60,'days')).toDate()}
                                            min={moment().toDate()}
                                            max={moment('2022-11-30').toDate()}
                                            component={DatePicker}
                                        />
                                        <hr />
                                        <div className={'k-form-buttons'}>
                                            <Button
                                                type={'button'}
                                                onClick={() => setFormDisplayed('none')}
                                            >
                                                {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                            </Button>

                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {localizationService.toLanguageString('custom.update', 'update')}
                                            </Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        }

                    </div>
                </div>
            }
        </div>
    );
}

export default EventPlanningFormScreen;
