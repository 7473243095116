import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import Login from "./pages/Login";
// import Dashboard from "./pages/Dashboard";
// import Planning from "./pages/Planning";
import Profile from "./pages/Profile";
// import Info from "./pages/Info";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import DrawerRouterContainer from "./components/DrawerRouterContainer";
import { AppContext } from "./AppContext";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags";
import currencyData from "cldr-core/supplemental/currencyData";
import weekData from "cldr-core/supplemental/weekData";

import frNumbers from "cldr-numbers-full/main/fr/numbers";
import frLocalCurrency from "cldr-numbers-full/main/fr/currencies";
import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian";
import frDateFields from "cldr-dates-full/main/fr/dateFields";

import usNumbers from "cldr-numbers-full/main/en/numbers";
import usLocalCurrency from "cldr-numbers-full/main/en/currencies";
import usCaGregorian from "cldr-dates-full/main/en/ca-gregorian";
import usDateFields from "cldr-dates-full/main/en/dateFields";

import esNumbers from "cldr-numbers-full/main/es/numbers";
import esLocalCurrency from "cldr-numbers-full/main/es/currencies";
import esCaGregorian from "cldr-dates-full/main/es/ca-gregorian";
import esDateFields from "cldr-dates-full/main/es/dateFields";

import { enMessages } from "./messages/en-US";
import { frMessages } from "./messages/fr";
import { esMessages } from "./messages/es";

import "hammerjs";
import "@progress/kendo-theme-default/dist/all.css";
import "./App.scss";
import { hydrateStores, useStores } from "./stores";
import HomeScreen from "./pages/Home";
import EventsScreen from "./pages/Events";
import EventScreen from "./pages/Event";
import DealersScreen from "./pages/Dealers";
import DealerFormScreen from "./pages/DealerForm";
import CheckApprovalScreen from "./pages/CheckApproval";
import CheckExceptionsScreen from "./pages/CheckExceptions";
import { initServices } from "./services";
import EventPlanningFormScreen from "./pages/EventPlanningForm";

load(
  likelySubtags,
  currencyData,
  weekData,
  frNumbers,
  frLocalCurrency,
  frCaGregorian,
  frDateFields,
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
  esNumbers,
  esLocalCurrency,
  esCaGregorian,
  esDateFields
);

loadMessages(esMessages, "es");
loadMessages(frMessages, "fr");
loadMessages(enMessages, "en-US");

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function doHydrate() {
      console.log("[App]", "hydrating stores");
      await hydrateStores();
      console.log("[App]", "init services");
      await initServices();
      console.log("[App]", "stores hydrated");
      setIsLoading(false);
    }
    doHydrate();
  }, []);

  const [contextState, setContextState] = React.useState({
    localeId: "en-US",
    authenticatedUser: null,
    selectedAccount: null,
  });
  const onLanguageChange = React.useCallback(
    (event) => {
      setContextState({ ...contextState, localeId: event.value.localeId });
    },
    [contextState, setContextState]
  );
  const onProfileChange = React.useCallback(
    (event) => {
      setContextState({ ...contextState, ...event.dataItem });
    },
    [contextState, setContextState]
  );

  return (
    <div className="App">
      {!isLoading ? (
        <LocalizationProvider language={contextState.localeId}>
          <IntlProvider locale={contextState.localeId}>
            <AppContext.Provider
              value={{ ...contextState, onLanguageChange, onProfileChange }}
            >
              <Router>
                <Switch>
                  <Route exact={true} path="/" component={HomeScreen} />
                  <Route exact={true} path="/login" component={Login} />
                  <Route
                    exact={true}
                    path="/password"
                    component={ForgotPassword}
                  />
                  <Route
                    exact={true}
                    path="/reset/:token"
                    component={ResetPassword}
                  />
                  <Route
                    exact={true}
                    path="/forgotPassword"
                    component={Login}
                  />
                  <DrawerRouterContainer>
                    <Switch>
                      <PrivateRoute exact={true} path="/events">
                        <EventsScreen />
                      </PrivateRoute>
                      <PrivateRoute exact={true} path="/check_approval">
                        <CheckApprovalScreen />
                      </PrivateRoute>
                      <PrivateRoute exact={true} path="/check_exceptions">
                        <CheckExceptionsScreen />
                      </PrivateRoute>
                      <PrivateRoute exact={false} path="/event/:event_id">
                        <EventScreen />
                      </PrivateRoute>
                      <PrivateRoute
                        exact={false}
                        path="/eventRequest/:event_request_id"
                      >
                        <EventPlanningFormScreen />
                      </PrivateRoute>
                      <PrivateRoute exact={true} path="/retailers">
                        <DealersScreen />
                      </PrivateRoute>
                      <PrivateRoute exact={false} path="/retailer/:dealer_id">
                        <DealerFormScreen />
                      </PrivateRoute>
                      {/* <PrivateRoute exact={true} path="/dashboard">
                        <Dashboard />
                      </PrivateRoute>
                      <PrivateRoute exact={true} path="/planning">
                        <Planning />
                      </PrivateRoute> */}
                      <PrivateRoute exact={true} path="/profile">
                        <Profile />
                      </PrivateRoute>
                      {/* <PrivateRoute exact={true} path="/info">
                        <Info />
                      </PrivateRoute> */}
                    </Switch>
                  </DrawerRouterContainer>
                </Switch>
              </Router>
            </AppContext.Provider>
          </IntlProvider>
        </LocalizationProvider>
      ) : null}
    </div>
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const { login } = useStores();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        login.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
