import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { useEffect, useState, useRef } from "react";
import { useServices } from "src/services";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar
} from "@progress/kendo-react-grid";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { DTGEvent } from "src/utils/classes";
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from "src/components/GridColumnMenus";
import multiColumnSort from "multi-column-sort";
import moment from 'moment';

const EventsScreen = () => {
  const localizationService = useLocalization();

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const _exportRDA = useRef<ExcelExport | null>(null);
  // const excelExportRDA = () => {
  //   if (_exportRDA.current !== null) {
  //     _exportRDA.current.save();
  //   }
  // };

  const { ui, login } = useStores();
  const { dtg } = useServices();
  let history = useHistory();

  const sortPresets: { [key: string]: State; } = {
    clear: {
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    default: {
      filter: {
        logic: "and", filters: [
          {
            logic: "and", filters: [
              { field: "sort_date", operator: "gte", value: moment().toDate() },
            ]
          },
          {
            logic: "or", filters: [
              { field: "status_pretty", operator: "eq", value: "New" },
              { field: "status_pretty", operator: "eq", value: "Eligible" },
              { field: "status_pretty", operator: "eq", value: "Planning" },
              { field: "status_pretty", operator: "eq", value: "Revise" },
              { field: "status_pretty", operator: "eq", value: "Review" },
              { field: "status_pretty", operator: "eq", value: "Approved" },
              { field: "status_pretty", operator: "eq", value: "Event Ending" },
              { field: "status_pretty", operator: "eq", value: "Event Ended" },
              { field: "status_pretty", operator: "eq", value: "Donation Requested" },
              { field: "status_pretty", operator: "eq", value: "Donation Approved" },
              { field: "status_pretty", operator: "eq", value: "Check Approved" },
              { field: "status_pretty", operator: "eq", value: "Reimbursement Requested" },
              { field: "status_pretty", operator: "eq", value: "Reimbursement Approved" },
            ]
          }
        ]
      },
      sort: [
        {
          field: 'status_pretty',
          dir: 'asc',
        },
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    action: {
      filter: {
        logic: "and", filters: [
          // {logic: "and", filters: [
          //     {field: "sort_date", operator: "gte", value: moment().toDate()},
          // ]},
          {
            logic: "or", filters: [
              { field: "status_pretty", operator: "eq", value: "New" },
              { field: "status_pretty", operator: "eq", value: "Review" },
              { field: "status_pretty", operator: "eq", value: "Reimbursement Submitted" },
              { field: "status_pretty", operator: "eq", value: "Donation Requested" },
              { field: "status_pretty", operator: "eq", value: "Event Ended" },
            ]
          }
        ]
      },
      sort: [
        {
          field: 'status_pretty',
          dir: 'asc',
        },
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    waiting: {
      filter: {
        logic: "and", filters: [
          // {logic: "and", filters: [
          //     {field: "sort_date", operator: "gte", value: moment().toDate()},
          // ]},
          {
            logic: "or", filters: [
              { field: "status_pretty", operator: "eq", value: "Eligible" },
              { field: "status_pretty", operator: "eq", value: "Planning" },
              { field: "status_pretty", operator: "eq", value: "Revise" },
              { field: "status_pretty", operator: "eq", value: "Donation Approved" },
            ]
          }
        ]
      },
      sort: [
        {
          field: 'status_pretty',
          dir: 'asc',
        },
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    yesterday: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "eq", value: moment().subtract(1, 'days').startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    today: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "eq", value: moment().startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    tomorrow: {
      filter: { logic: "and", filters: [{ logic: "and", filters: [{ field: "sort_date", operator: "eq", value: moment().add(1, 'days').startOf('day').toDate() }] }] },
      sort: [
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    thisWeek: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "gte", value: moment().startOf('isoWeek').startOf('day').toDate() },
            { field: "sort_date", operator: "lte", value: moment().endOf('isoWeek').startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    nextWeek: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "gte", value: moment().add(1, 'weeks').startOf('isoWeek').startOf('day').toDate() },
            { field: "sort_date", operator: "lte", value: moment().add(1, 'weeks').endOf('isoWeek').startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    lastWeek: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "gte", value: moment().subtract(1, 'weeks').startOf('isoWeek').startOf('day').toDate() },
            { field: "sort_date", operator: "lte", value: moment().subtract(1, 'weeks').endOf('isoWeek').startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    past: {
      filter: {
        logic: "and", filters: [
          {
            logic: "and", filters: [
              { logic: "and", filters: [{ field: "sort_date", operator: "lt", value: moment().toDate() }] },
            ]
          },
        ]
      },
      sort: [
        {
          field: 'status_pretty',
          dir: 'asc',
        },
        {
          field: 'sort_date',
          dir: 'desc',
        },
      ]
    },
  }

  const [dataState, setDataState] = useState<State>(sortPresets.default);

  type Direction = "ASC" | "DESC";
  type SortObject<T> = {
    [key in keyof T]?: Direction;
  };

  const customProcess = (data: any[], state: State) => {
    const preProcessed = process(data, state).data;
    // custom sort
    const getColumnValues = (column: any, value: any) => {
      switch (column) {
        // case 'sort_status':
        //     return value.data.value;
        default:
          return value;
      }
    }

    const sortOrder: SortObject<any> = {};

    for (const s of (state.sort || [])) {
      let fieldToSort = s.field;

      switch (fieldToSort) {
        case 'status_pretty':
          fieldToSort = 'sort_status';
          break;
      }

      sortOrder[fieldToSort] = s.dir?.toUpperCase() as Direction;
    };

    let newData = [];
    if (state.sort?.length) {
      newData = multiColumnSort(
        preProcessed,
        sortOrder,
        getColumnValues
      );
    } else {
      newData = preProcessed;
    }

    return {
      data: newData,
      total: preProcessed.length,
    } as DataResult;
  }


  const processEvents = (events: DTGEvent[]): DTGEvent[] => {
    return events.map((e) => {
      return new DTGEvent(e);
    });
  };

  // const [planners, setPlanners] = useState<UserDetails[]>([]);
  const [eventList, setEventList] = useState<DTGEvent[]>([]);
  const [dataResult, setDataResult] = useState<DataResult>(
    customProcess(eventList, dataState)
  );
  const [rdaData, setRDAData] = useState<DataResult>();

  useEffect(() => {
    ui.setIsGettingEvents(true);
    dtg.getEvents(7).then((ret) => {
      // TODO: make season dynamic
      ui.setIsGettingEvents(false);

      const processedEvents = processEvents(
        (ret as DTGFetchResult).result || []
      );

      setEventList(processedEvents);
      setDataResult(customProcess(processedEvents, dataState));
      const rdaTemp = processedEvents.filter((e) => e.event_id && !e.cancelled);
      setRDAData({
        data: rdaTemp,
        total: rdaTemp.length,
      });
    });
    // dtg.getPlanners().then((plannersResult) => {
    //   setPlanners((plannersResult as DTGFetchResult).result as UserDetails[]);
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const EventActionsCell = (props: GridCellProps) => {
    const { event_id, event_request_id } = new DTGEvent(props.dataItem);

    return (
      <td>
        <Button
          className="k-button k-primary"
          // onClick={() => props.enterEdit(props.dataItem)}
          onClick={() => {
            if (event_id) {
              history.push(`/event/${event_id}`);
            } else {
              history.push(`/eventRequest/${event_request_id}`);
            }
          }}
        >
          View / Edit
        </Button>
      </td>
    );
  };

  const doDataState = (state: State) => {
    console.log('event.dataState', state);
    setDataResult(customProcess(eventList, state));
    setDataState(state);
  }

  // const plannersCell = (props: GridCellProps) => {
  //   const { display_name, eligibility_custom_data } = new DTGEvent(props.dataItem);
  //   const assignedPlannerID = JSON.parse(eligibility_custom_data || '').assigned_planner
  //   if (display_name) {
  //     return (
  //       <td>
  //         <p>{display_name}</p>
  //       </td>
  //     )
  //   }
  //   else if (assignedPlannerID && planners.length) {
  //     return (
  //       <td>
  //         <p>{planners?.find((x) => x.user_id === assignedPlannerID)?.display_name}</p>
  //       </td>
  //     )
  //   } else {
  //     return (
  //       <td>
  //       </td>
  //     )
  //   }
  // };


  return (
    <div id="Dashboard" className="dashboard-page main-content">
      <div className="card-container grid">
        <h3 className="card-title">
          {login.user?.credentials.display_name}:{" "}
          {localizationService.toLanguageString("custom.events", "events")}
        </h3>
        <div className="card-component">
          <ExcelExport data={dataResult.data} ref={_export} fileName={`events_export_${moment().format('YYYYMMDD-hhmma')}.xlsx`}>
            <ExcelExportColumn field="event_request_id" title="Request ID" />
            <ExcelExportColumn field="event_id" title="Latitude Event ID" />
            <ExcelExportColumn field="ffs_event_id" title="FFS Event ID" />
            <ExcelExportColumn field="event_type_display" title="Event Type" />
            {/* <ExcelExportColumn field="event_name" title="Event Name" /> */}
            <ExcelExportColumn field="dealer_name" title={localizationService.toLanguageString('custom.dealer', 'dealer')} />
            <ExcelExportColumn field="charity_partner" title="Charity" />
            {/* <ExcelExportColumn field="display_name" title="Planner" /> */}
            <ExcelExportColumn field="sort_date" title="Event Date" />
            <ExcelExportColumn field="start_time" title="Start Time" />
            <ExcelExportColumn field="end_time" title="End Time" />
            <ExcelExportColumn field="time_zone" title="Time Zone" />
            <ExcelExportColumn field="status_pretty" title="Status" />
            <ExcelExportColumn field="check_amount" title="Check Amount" />
          </ExcelExport>
          <ExcelExport
            data={rdaData?.data}
            fileName={`IPSOS_events_export_${moment().format('YYYYMMDD-hhmma')}.xlsx`}
            ref={_exportRDA}
          >
            <ExcelExportColumn field="ffs_lincoln_campaign" title="Campaign ID" />
            <ExcelExportColumn field="ffs_lincoln_sequence" title="Sequence #" />
            <ExcelExportColumn field="ffs_event_id" title="Event ID" />
            <ExcelExportColumn field="start_date_export" title="Start Date" />
            <ExcelExportColumn field="end_date_export" title="End Date" />
            <ExcelExportColumn field="event_name" title="Event" />
            <ExcelExportColumn field="event_name" title="Event name for survey (i.e., consumer friendly)" />
            <ExcelExportColumn field="charity_partner" title="Event Partner Name (enter n/a if not applicable)" />
            <ExcelExportColumn field="address" title="Event Street Address" />
            <ExcelExportColumn field="city" title="Event City" />
            <ExcelExportColumn field="state" title="Event State" />
            <ExcelExportColumn field="zip_code" title="Event Zip Code" />

            <ExcelExportColumn field="market_area" title="Market" />
            <ExcelExportColumn field="audience_type" title="Audience Type" />
            <ExcelExportColumn field="category" title="Event Category" />
            <ExcelExportColumn field="language_option" title="Language Option" />
            <ExcelExportColumn field="attendance" title="Attendance" />
            <ExcelExportColumn field="indoor_outdoor" title="Event Indoor/Outdoor" />
            <ExcelExportColumn field="test_drive" title="Test Drive" />
            <ExcelExportColumn field="regional" title="Regional/National Program" />
            <ExcelExportColumn field="lincoln_event_partner" title="Lincoln Event Partner" />
            <ExcelExportColumn field="audience_type_full" title="Audience Type" />
            <ExcelExportColumn field="category_long" title="Event Category" />
            <ExcelExportColumn field="test_drive_full" title="Test Drive" />
            <ExcelExportColumn field="regional_full" title="Regional/National Program" />
          </ExcelExport>
          <Grid
            data={dataResult}
            sortable={{ mode: "multiple" }}
            {...dataState}
            onDataStateChange={(event: GridDataStateChangeEvent) => {
              console.log("event.dataState", event.dataState);
              setDataResult(customProcess(eventList, event.dataState));
              setDataState(event.dataState);
            }}
            scrollable="none"
          >
            <GridToolbar>
              Quick Views:
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.default)}>Default</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.action)}>Action Needed</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.waiting)}>Waiting on Dealer</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.today)}>Today's Events</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.tomorrow)}>Tomorrow</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.thisWeek)}>This Week</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.nextWeek)}>Next Week</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.yesterday)}>Yesterday</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.lastWeek)}>Last Week</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.clear)}>Clear All</button>
              <ToolbarSpacer />
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={excelExport}>Export</button>
              {/* <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={excelExportRDA}>IPSOS Export</button> */}
            </GridToolbar>
            {/* <GridColumn
              field="event_name"
              title="Event Name"
              columnMenu={ColumnMenu}
            /> */}
            <GridColumn
              field="dealer_name"
              title={localizationService.toLanguageString(
                "custom.dealer",
                "dealer"
              )}
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="charity_partner"
              title="Charity"
              columnMenu={ColumnMenu}
            />
            {/* <GridColumn
              title="Planner"
              cell={plannersCell}
              columnMenu={ColumnMenu}
            /> */}
            <GridColumn
              field="sort_date"
              title="Event Date"
              format="{0:E, MMM dd yyyy}"
              filter="date"
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="status_pretty"
              title="Status"
              columnMenu={ColumnMenuCheckboxFilter(eventList, {
                searchBox: () => null,
              })}
            />
            <GridColumn
              field="check_amount_pretty"
              title="Check"
            />
            <GridColumn cell={EventActionsCell} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default observer(EventsScreen);
