import React, { MouseEventHandler, useEffect, useState } from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  LocalizationService,
  useLocalization,
} from "@progress/kendo-react-intl";
import {
  Drawer,
  DrawerContent,
  DrawerItemProps,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";

import { Header } from "./Header";
import { useServices } from "src/services";
import { observer } from "mobx-react-lite";
import { useStores } from "src/stores";

const DrawerRouterContainer: React.FC<RouteComponentProps> = (props) => {
  const { history, location, children } = props;
  const { dtg } = useServices();
  const { login } = useStores();

  const items: DrawerItemProps[] = [
    {
      name: "events",
      icon: "far fa-calendar-star",
      selected: true,
      route: "/events",
      planner: "true",
    },
    { separator: true },
    {
      name: "dealers",
      icon: "fas fa-cars",
      route: "/retailers",
      planner: "true",
    },
    {
      name: "check_approval",
      icon: "fas fa-file-check",
      route: "/check_approval",
      planner: "false",
      //   checkApprovalAccess: login.user?.credentials.user_id === 462,
      // excluded: login.user?.credentials.user_id !== 462 ? "true" : "false",
    },
    {
      name: "check_exceptions",
      icon: "fas fa-money-check",
      route: "/check_exceptions",
      planner: "false",
      //   checkApprovalAccess: login.user?.credentials.user_id === 462,
      // excluded: login.user?.credentials.user_id !== 462 ? "true" : "false",
    },
    // { name: 'users', icon: 'far fa-users', route: '/users' },
    { separator: true },
    // { name: 'myAccount', icon: 'fas fa-user-circle', route: '/profile', planner: 'true' },
    {
      name: "logout",
      icon: "fas fa-sign-out",
      onClick: async () => {
        console.log("log out");
        await dtg.doLogout();
        history.replace("/");
      },
      all: "true",
    },
  ];

  const [isSmallerScreen, setIsSmallerScreen] = useState<boolean>(
    window.innerWidth < 768
  );
  const [expanded, setExpanded] = useState<boolean>(!isSmallerScreen);

  const resizeWindow = () => {
    setIsSmallerScreen(window.innerWidth < 768);
  };

  const handleClick: MouseEventHandler = () => {
    setExpanded(!expanded);
  };

  const handleSelect = (e: DrawerSelectEvent) => {
    setExpanded(!isSmallerScreen);
    history.push(e.itemTarget.props.route);
  };

  const getSelectedItem = (pathName: string) => {
    let currentPath = items.find((item) => item.route === pathName);
    if (currentPath?.name) {
      return currentPath.name;
    }
  };

  // componentDidUpdate() {
  //     try {
  //         const parent = window.parent;
  //         if(parent) {
  //             parent.postMessage({ url: location.pathname, demo: true }, "*")
  //         }
  //     } catch(err) {
  //         console.warn('Cannot access iframe')
  //     }
  // }

  useEffect(() => {
    window.addEventListener("resize", resizeWindow, false);
    resizeWindow();
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  });

  let selected = getSelectedItem(location.pathname);
  const localizationService: LocalizationService = useLocalization();

  return (
    <React.Fragment>
      <Header
        onButtonClick={handleClick}
        page={localizationService.toLanguageString(
          `custom.${selected}`,
          "LCC Admin"
        )}
      />
      <Drawer
        expanded={expanded}
        animation={{ duration: 100 }}
        items={items
          .filter((navItem) => {
            if (navItem.name === "check_approval") {
              if (login.user?.credentials.hasPermission('dtg_check_approval')) {
                return navItem;
              } else {
                return null;
              }
            }
            return (
              (navItem.all && !navItem.excluded) ||
              (login.user?.credentials.hasPermission('admin') &&
                !navItem.excluded) ||
              (login.user?.credentials.hasPermission('planner') &&
                navItem.planner &&
                !navItem.excluded)
            );
          })
          .map((item) => ({
            ...item,
            text: localizationService.toLanguageString(
              `custom.${item.name}`,
              item.name
            ),
            selected: item.name === selected,
          }))}
        position="start"
        mode={isSmallerScreen ? "overlay" : "push"}
        mini={isSmallerScreen ? false : true}
        onOverlayClick={handleClick}
        onSelect={handleSelect}
      >
        <DrawerContent style={{ height: 1066 }}>{children}</DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};

export default withRouter(observer(DrawerRouterContainer));
