import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useEffect, useState } from "react";
import { useServices } from "src/services";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { Dealer } from "src/utils/classes";
import { DataResult, process, State } from "@progress/kendo-data-query";
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from "src/components/GridColumnMenus";

const DealersScreen = () => {
  const localizationService = useLocalization();

  // const { ui } = useStores();
  const { dtg } = useServices();
  let history = useHistory();

  const [dataState, setDataState] = useState<State>({
    filter: {
      logic: "and",
      filters: [
        {
          logic: "or",
          filters: [
            { field: "activeGridCell", operator: "eq", value: "Active" },
          ],
        },
      ],
    },
    sort: [
      {
        field: "created_timestamp",
        dir: "desc",
      },
    ],
  });

  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [dataResult, setDataResult] = useState<DataResult>(
    process(dealers, dataState)
  );

  const processDealers = (dealers: any[]): Dealer[] => {
    if (!Array.isArray(dealers)) {
      return [];
    }
    return dealers.map((d) => new Dealer(d));
  };

  useEffect(() => {
    // ui.setIsGettingDealers(true);
    dtg.getDealers().then((ret) => {
      const processedDealers = processDealers(
        (ret as DTGFetchResult).result || []
      );
      // ui.setIsGettingDealers(false);

      setDealers(processedDealers);
      setDataResult(process(processedDealers, dataState));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DealerActionsCell = (props: GridCellProps) => {
    const { dealer_id } = props.dataItem as Dealer;

    return (
      <td>
        <Button
          className="k-button k-primary"
          onClick={() => {
            history.push(`/retailer/${dealer_id}`);
          }}
        >
          Edit
        </Button>
      </td>
    );
  };

  return (
    <>
      <div id="Dashboard" className="dashboard-page main-content">
        <div className="card-container grid">
          <h3 className="card-title">
            {localizationService.toLanguageString("custom.dealers", "dealers")}
          </h3>
          {/* <Button primary={!ui.events.length} onClick={handleEventRequestButton}>
                        { ui.events.length ?
                            localizationService.toLanguageString('custom.requestNewEvent','requestNewEvent') :
                            localizationService.toLanguageString('custom.beginHere','beginHere')
                        }
                    </Button> */}
          <div className="card-component">
            <Grid
              data={dataResult}
              scrollable="none"
              sortable={{ mode: "multiple" }}
              {...dataState}
              onDataStateChange={(event: GridDataStateChangeEvent) => {
                console.log("event.dataState", event.dataState);
                setDataResult(process(dealers, event.dataState));
                setDataState(event.dataState);
              }}
            >
              <GridColumn
                field="dealer_name"
                title={localizationService.toLanguageString(
                  "custom.dealer",
                  "dealer"
                )}
                columnMenu={ColumnMenu}
              />
              <GridColumn
                field="created_timestamp"
                title="Signup Date"
                format="{0:E, MMM dd yyyy}"
                filter="date"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                field="century_approved"
                title="Allowed"
                columnMenu={ColumnMenuCheckboxFilter(dealers, {
                  searchBox: () => null,
                })}
              />
              <GridColumn
                field="activeGridCell"
                title="Status"
                columnMenu={ColumnMenuCheckboxFilter(dealers, {
                  searchBox: () => null,
                })}
              />
              <GridColumn cell={DealerActionsCell} />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(DealersScreen);
