import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { useEffect, useRef, useState } from "react";
import { useServices } from "src/services";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import {
  DataResult,
  process,
  State,
} from "@progress/kendo-data-query";
import { QueuedCheck } from "src/utils/classes";
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from "src/components/GridColumnMenus";
import multiColumnSort from "multi-column-sort";
import moment from "moment";

const CheckApprovalScreen = () => {
  const localizationService = useLocalization();
  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const { ui, login } = useStores();
  const { dtg } = useServices();

  const sortPresets: { [key: string]: State; } = {
    clear: {
      sort: [
        {
          field: 'created_date',
          dir: 'asc',
        },
      ]
    },
    default: {
      filter: {logic: "and", filters: [
        {logic: "or", filters: [
          {field: "status", operator: "eq", value: "Pending"}, 
        ]}
      ]},
      sort: [
        {
          field: 'status',
          dir: 'asc',
        },
        {
          field: 'created_timestamp',
          dir: 'asc',
        },
      ]
    },
    week: {
      filter: {logic: "and", filters: [{logic: "and", filters: [
        {field: "check_approved", operator: "gte", value: moment().day(-5).startOf('day').toDate()}, // last friday
        {field: "check_approved", operator: "lte", value: moment().day(4).endOf('day').toDate()}, // this thursday
      ]}]},
      sort: [
        {
            field: 'created_timestamp',
            dir: 'asc',
        },
      ]
    },
    all: {
      sort: [
          {
              field: 'status',
              dir: 'asc',
          },
          {
              field: 'created_timestamp',
              dir: 'asc',
          },
      ]
    },
  }

  const [dataState, setDataState] = useState<State>(sortPresets.default);
  const [checkQueue, setCheckQueue] = useState<QueuedCheck[]>([]);
  const [selectedCheck, setSelectedCheck] = useState<QueuedCheck>();

  type Direction = "ASC" | "DESC";
  type SortObject<T> = {
    [key in keyof T]?: Direction;
  };

  const customProcess = (data: any[], state: State) => {
    const preProcessed = process(data, state).data;
    // custom sort
    const getColumnValues = (column: any, value: any) => {
        switch (column) {
            // case 'sort_status':
            //     return value.data.value;
            default:
                return value;
        }
    }

    const sortOrder:SortObject<any> = {};
    
    for (const s of (state.sort || [])) {
        let fieldToSort = s.field;

        switch (fieldToSort) {
            case 'status':
                fieldToSort = 'sort_status';
                break;
        }
        
        sortOrder[fieldToSort] = s.dir?.toUpperCase() as Direction;
    };

    let newData = [];
    if (state.sort?.length) {
        newData = multiColumnSort(
            preProcessed,
            sortOrder,
            getColumnValues
        );
    } else{
        newData = preProcessed;
    }

    return {
        data: newData,
        total: preProcessed.length,
    } as DataResult;
  }

  const processChecks = (checks: any[]): QueuedCheck[] => {
    return checks.map((check) => {
      console.log("CHECK", check);
      return new QueuedCheck(check);
    });
  };

  const [dataResult, setDataResult] = useState<DataResult>(
    customProcess(checkQueue, dataState)
  );

  const [confirmApproveVisible, setConfirmApproveVisible] =
    useState<boolean>(false);

  const approveCheck = (check: QueuedCheck) => {
    setSelectedCheck(check);
    setConfirmApproveVisible(true);
  };

  const doApproveCheck = () => {
    ui.setIsApprovingCheck(true);
    const finish = () => {
      ui.setIsGettingEvents(true);
      dtg.getCheckQueue(6).then((ret) => {
        // TODO: make season dynamic
        ui.setIsGettingEvents(false);
        const processedChecks = processChecks(
          (ret as DTGFetchResult).result || []
        );
        setCheckQueue(processedChecks);
        setDataResult(customProcess(processedChecks, dataState));
        setConfirmApproveVisible(false);
        ui.setIsGettingEvents(false);
        ui.setIsApprovingCheck(false);
      });
    }

    if (selectedCheck?.check_type === 'exception') {
      dtg.approveCheckException(selectedCheck?.check_queue_id).then(finish);
    } else {
      dtg.submitCheckApproval(selectedCheck?.check_queue_id).then(finish);
      }
  };

  const doDataState = (state: State) => {
    console.log('event.dataState',state);
    setDataResult(customProcess(checkQueue, state));
    setDataState(state);
  }

  useEffect(() => {
    ui.setIsGettingEvents(true);
    dtg.getCheckQueue(6).then((ret) => {
      // TODO: make season dynamic
      ui.setIsGettingEvents(false);
      const processedChecks = processChecks(
        (ret as DTGFetchResult).result || []
      );

      setCheckQueue(processedChecks);
      setDataResult(customProcess(processedChecks, dataState));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const EventActionsCell = (props: GridCellProps) => {
    const thisCheck = props.dataItem as QueuedCheck;
    if (thisCheck.status === "Approved") {
      return (
        <td>
          <p>Check Approved</p>
        </td>
      );
    }
    return (
      <td>
        <Button
          className="k-button k-primary approve-btn"
          onClick={() => approveCheck(thisCheck)}
        >
          Approve Check
        </Button>
      </td>
    );
  };
  return (
    <div id="Dashboard" className="dashboard-page main-content">
      {confirmApproveVisible && (
        <Dialog
          title={"Please confirm"}
          onClose={() => {
            setConfirmApproveVisible(false);
            ui.setIsApprovingCheck(false);
          }}
        >
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Approve this check?
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => setConfirmApproveVisible(false)}
            >
              Cancel
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => doApproveCheck()}
              disabled={ui.isApprovingCheck}
            >
              Yes, Approve
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      <div className="card-container grid">
        <h3 className="card-title">
          {login.user?.credentials.display_name}:{" "}
          {localizationService.toLanguageString(
            "custom.check_approval",
            "Check Approval"
          )}
        </h3>
        <div className="card-component">
          <ExcelExport data={dataResult.data} ref={_export} fileName={`checks_export_${moment().format('YYYYMMDD-hhmma')}.xlsx`}>
            <ExcelExportColumn field="check_type_pretty" title="Check Type"  />
            <ExcelExportColumn field="check_amount" title="Check Amount"  />
            <ExcelExportColumn field="event_name" title="Event Name"  />
            <ExcelExportColumn field="start_date" title="Event Date"  />
            <ExcelExportColumn field="dealer_name" title={localizationService.toLanguageString('custom.dealer','dealer')}  />
            <ExcelExportColumn field="ffs_event_id" title="FFS Event ID"  />

            <ExcelExportColumn field="created_timestamp" title="Request Date" />
            <ExcelExportColumn field="check_approved" title="Approved Date" />
            <ExcelExportColumn field="status" title="Status"  />
          </ExcelExport>
          <Grid
            data={dataResult}
            sortable={{ mode: "multiple" }}
            {...dataState}
            onDataStateChange={(event: GridDataStateChangeEvent) => {
              console.log("event.dataState", event.dataState);
              setDataResult(customProcess(checkQueue, event.dataState));
              setDataState(event.dataState);
            }}
            scrollable="none"
          >
            <GridToolbar>
              Quick Views:
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.default)}>Action Needed</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.week)}>Approved Week Fri-Thur</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.all)}>Show All</button>
              <ToolbarSpacer />
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={excelExport}>Export</button>
            </GridToolbar>
            <GridColumn
              field="check_type_pretty"
              title="Check Type"
              columnMenu={ColumnMenuCheckboxFilter(checkQueue, {
                searchBox: () => null,
              })}
            />
            <GridColumn
              field="check_amount_pretty"
              title="Check Amount"
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="event_name"
              title="Event Name"
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="dealer_name"
              title={localizationService.toLanguageString(
                "custom.dealer",
                "dealer"
              )}
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="ffs_event_id"
              title={"FFS Event ID"}
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="created_timestamp"
              title="Request Date"
              format="{0:E, MMM dd yyyy}"
              filter="date"
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="check_approved"
              title="Approved Date"
              format="{0:E, MMM dd yyyy}"
              filter="date"
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="status"
              title="Status"
              cell={EventActionsCell}
              columnMenu={ColumnMenuCheckboxFilter(checkQueue, {
                searchBox: () => null,
              })}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default observer(CheckApprovalScreen);
