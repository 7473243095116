export const basePath = `admin`;
export const getApiURL = (ENV = process.env.REACT_APP_ENV, path = basePath) => {
  // const domain = `${process.env.API_DOMAIN}${path}`;
  // const isLocalhost = process.env.API_DOMAIN.indexOf('localhost') > -1;
  let baseURL = "api.latitudeshowtracker.com/";
  let protocol = "https://";

  if (ENV !== "staging" && ENV !== "production") {
    protocol = "http://";
    baseURL = "localhost:3000/";
  }
  if (ENV === "staging") {
    baseURL = "staging.api.latitudeshowtracker.com/";
  }

  const domain = `${baseURL}${basePath}`;

  return `${protocol}${domain}/`;
};

export const API_URL = getApiURL();
