import * as React from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router";
import { Button } from "@progress/kendo-react-buttons";

// import kendoka from "../assets/kendoka.png";
// import github from "../assets/github-icon.svg";

const HomeScreen = () => {
  const localizationService = useLocalization();
  const history = useHistory();
  return (
    <div id="Home" className="main-content">
      <div className="content">
        <h1>Lincoln Admin</h1>
        <div className="button-group">
          <Button
            className="k-button k-primary"
            onClick={() => {
              history.replace("/login");
            }}
          >
            {localizationService.toLanguageString("custom.logIn")}
          </Button>
          <Button
            className="k-button"
            onClick={() => {
              history.replace("/password");
            }}
          >
            {localizationService.toLanguageString("custom.forgotPassword")}
          </Button>
        </div>
      </div>
      <div className="footer" />
    </div>
  );
};

export default HomeScreen;
