import * as React from "react";
import {
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
  GridColumnMenuSort,
  GridColumnMenuCheckboxFilterProps,
} from "@progress/kendo-react-grid";

export const ColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuSort {...props} />
      <GridColumnMenuFilter expanded={true} {...props} />
    </div>
  );
};

export const ColumnMenuCheckboxFilter = (data: any, filterProps: Partial<GridColumnMenuCheckboxFilterProps>|undefined) => {
  return (props: GridColumnMenuProps) => {
    return (
      <div>
        <GridColumnMenuSort {...props} />
        <GridColumnMenuCheckboxFilter
          data={data}
          expanded={true}
          {...filterProps}
          {...props}
        />
      </div>
    );
  }
};
