/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import { Input } from 'src/components/form/Input';
import { DatePicker } from 'src/components/form/DatePicker';
import { AppContext } from '../AppContext'
import { NumericTextBox } from 'src/components/form/NumericTextBox';
import { DropDownList } from 'src/components/form/DropDownList';
import { useHistory } from "react-router";
import moment from 'moment';
import {
  requiredValidator,
  address1Validator,
  cityValidator,
  stateValidator,
  zipValidator,
  ffsIDValidator
} from '../validators';


const CheckExceptionsScreen = () => {
  const localizationService = useLocalization();
  const {...formValues} = React.useContext(AppContext);
  let history = useHistory();
  const { login } = useStores();
  const { dtg } = useServices();

  // const [confirmSubmitVisible, setConfirmSubmitVisible] = useState<boolean>(false);
  const [ formError, setFormError ] = useState<string>();
  const [ showForceAddressPopup, toggleShowForceAddressPopup] = useState<boolean>(false);
  const [ enteredAddress, setEnteredAddress ] = useState<any>({});
  // hack to get the scroll fix to work since the whole form doesn't render right away.
  const [ cycleRefs, setCycleRefs ] = useState<any>(1);

  const onSubmit = React.useCallback(
    async (dataItem) => {
      setEnteredAddress(dataItem)
      // finagle this data into the check queue object
      const queuedCheck = {
        check_type: 'exception',
        check_amount: dataItem.amount,
        custom_data: JSON.stringify(dataItem),
        force_validate: dataItem.force_validate,
      };

      const call: any = await dtg.requestCheckException(queuedCheck);
      console.log('call!!!!!', call);
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "submit check exception Error");
        return setFormError(call.result.errors.join("\n"));
      }
      if (
        Object.keys(call.result).includes("verified") &&
        call.result.verified === false
      ) {
        console.log("success but not verified!!", enteredAddress);
        // display message and display button to force through
        toggleShowForceAddressPopup(true);
      } else {
        console.log("[EventDetailScreen]", "Donation Approved");
        history.push("/events");
      }
    },
    [dtg, history]
  );

    const dateRef:any = React.useRef(null);
    const amountRef:any = React.useRef(null);

    console.log('dateRef', dateRef);

    const handleOnWheel = (event:any) => {
      event.stopPropagation();
    };

    React.useEffect(() => {
      dateRef?.current?.element.addEventListener('wheel', handleOnWheel);
      amountRef?.current?.element.addEventListener('wheel', handleOnWheel);

      return () => {
        dateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
        amountRef?.current?.element.removeEventListener('wheel', handleOnWheel);
      };
    }, [cycleRefs]);

  return (
    <div id="Dashboard" className="dashboard-page main-content">
      <div className="card-container grid">
        <h3 className="card-title">
          {login.user?.credentials.display_name}:{" "}
          {localizationService.toLanguageString(
            "custom.check_exceptions",
            "Check Exceptions"
          )}
        </h3>
        <div className="card-component">
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...formValues,
        }}
          render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                  { formError && <Error>{formError}</Error> }
                  <Field
                    component={DropDownList}
                    id={'check_type'}
                    name={'check_type'}
                    label={localizationService.toLanguageString('custom.check_type', 'Check Type')}
                    data={['Reissue', 'Additional', 'One Off',]}
                    onChange={() => setCycleRefs(cycleRefs + 1)}
                    required={true}
                  />
                  {formRenderProps.valueGetter('check_type') ?
                  (
                    <>
                    {!['One Off'].includes(formRenderProps.valueGetter('check_type')) ?
                    <Field
                        id={'ffs_event_id'}
                        name={'ffs_event_id'}
                        label={'FFS ID/Confirmation ID'}
                        format={'######'}
                        validator={formRenderProps.valueGetter('check_type') && !['One Off'].includes(formRenderProps.valueGetter('check_type')) ? ffsIDValidator : undefined}
                        component={Input}
                        disabled={!formRenderProps.valueGetter('check_type') || ['One Off'].includes(formRenderProps.valueGetter('check_type')) }
                    />
                    :
                    null
                    }
                    <Field
                        id={'event_date'}
                        name={'event_date'}
                        childRef={dateRef}
                        required={true}
                        label={localizationService.toLanguageString('custom.event_date', 'Event Date')}
                        format="MM/dd/yyyy"
                        max={moment().toDate()}
                        component={DatePicker}
                        validator={requiredValidator}
                    />
                     <Field
                        id={'dealer_name'}
                        name={'dealer_name'}
                        label={'Dealer Name'}
                        validator={requiredValidator}
                        component={Input}
                    />
                     <Field
                        id={'event_name'}
                        name={'event_name'}
                        label={'Event Name'}
                        validator={requiredValidator}
                        component={Input}
                    />
                    <Field
                        id={'check_payable_to'}
                        name={'check_payable_to'}
                        label={'Check Payable To'}
                        validator={requiredValidator}
                        component={Input}
                    />
                     <Field
                        id={'amount'}
                        name={'amount'}
                        label={'Amount'}
                        childRef={amountRef}
                        validator={requiredValidator}
                        component={NumericTextBox}
                    />
                     <Field
                        id={'check_attention_of'}
                        name={'check_attention_of'}
                        label={'Check Addressed To'}
                        validator={requiredValidator}
                        component={Input}
                    />
                    <Field
                        id={"address_1"}
                        name={"address_1"}
                        // type={'input'}
                        label={"Check Delivery Address"}
                        validator={address1Validator}
                        component={Input}
                      />
                      <Field
                        id={"address_2"}
                        name={"address_2"}
                        // type={'input'}
                        label={localizationService.toLanguageString(
                          "custom.address_2",
                          "address2"
                        )}
                        component={Input}
                      />
                      <Field
                        id={"city"}
                        name={"city"}
                        // type={'input'}
                        label={localizationService.toLanguageString(
                          "custom.city",
                          "city"
                        )}
                        validator={cityValidator}
                        component={Input}
                      />
                      <Field
                        id={"state"}
                        name={"state"}
                        // type={'input'}
                        label={localizationService.toLanguageString(
                          "custom.state",
                          "state"
                        )}
                        validator={stateValidator}
                        component={Input}
                      />
                      <Field
                        id={"zip_code"}
                        name={"zip_code"}
                        // type={'input'}
                        label={localizationService.toLanguageString(
                          "custom.zip",
                          "zip"
                        )}
                        validator={zipValidator}
                        component={Input}
                      />
                    </>
                  ) :  null }
                  <hr />
                  {showForceAddressPopup &&
                    <Dialog style={{ width: '60%', marginLeft: '20%'}}>
                        <div className="address_unverified_text">
                          <p>
                            This address could not be verified by USPS.
                            Please see below and confirm that the address is
                            correct. If not, select "Cancel" and edit the address.
                            If the address is correct, press "Submit Check".
                          </p>
                        </div>
                        <div className="dealer_address_cntr">
                          <p>
                            {enteredAddress?.address_1}<br/>
                            {enteredAddress?.address_2}<br/>
                            {enteredAddress?.city}, {enteredAddress?.state} {enteredAddress?.zip_code}
                          </p>
                        </div>
                      <DialogActionsBar>
                        <Button
                          primary={true}
                          type={"button"}
                          disabled={!formRenderProps.allowSubmit}
                          onClick={() => {
                            const dataCopy = {
                              ...enteredAddress,
                              force_validate: true,
                            }
                            onSubmit(dataCopy)
                          }}
                        >
                          Submit Check
                        </Button>
                      &nbsp;
                      <Button
                        onClick={() => {
                          toggleShowForceAddressPopup(false)
                        }}
                      >
                        {localizationService.toLanguageString(
                          "custom.cancel",
                          "cancel"
                        )}
                      </Button>
                    </DialogActionsBar>

                    </Dialog>
                  }
                  <div className={'card-buttons'}>
                      <Button
                          primary={true}
                          type={'submit'}
                          disabled={!formRenderProps.allowSubmit}
                      >
                          Submit Exception
                      </Button>
                  </div>
              </FormElement>
                )}
              />
        </div>
      </div>
    </div>
  );
};

export default observer(CheckExceptionsScreen);
