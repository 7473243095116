/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useLocalization } from "@progress/kendo-react-intl";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {
  emailValidator,
  requiredValidator,
  passwordMatchValidator,
  partsCodeValidator,
  address1Validator,
  cityValidator,
  stateValidator,
  zipValidator,
  phoneValidator,
  newPasswordValidator,
  dollarValidator,
  // dealerCodeValidator
} from "../validators";
import raw_timezones from "src/stores/timezones.json";

import { AppContext } from "../AppContext";
import { useServices } from "src/services";
import { TextArea, Input } from "@progress/kendo-react-inputs";
import { DatePicker } from 'src/components/form/DatePicker';
import { TimePicker } from 'src/components/form/TimePicker';
import { NumericTextBox } from 'src/components/form/NumericTextBox';
import { MaskedTextBox } from "src/components/form/MaskedTextBox";
import { useHistory } from "react-router";
import moment from "moment";
import capitalize from "src/utils/capitalize";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { DropDownList } from "src/components/form/DropDownList";
import { DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Upload } from "src/components/form/Upload";
import { Dealer, DTGEvent, UserDetails } from "src/utils/classes";
import { useStores } from "src/stores";

type EventScreenParams = {
  event_id: string;
};

// const dealerTypes = [
//   { value: "L", text: "Lincoln" },
//   { value: "FL", text: "Ford/Lincoln" },
// ];

// const dealerRegions = [
//   { value: "CE", text: "Central Market Area" },
//   { value: "EMA", text: "East Market Area" },
//   { value: "GL", text: "Great Lakes Market Area" },
//   { value: "SE", text: "Southeast Market Area" },
//   { value: "W", text: "West Market Area" },
// ];

const EventDetailScreen = () => {
  const { ...formValues } = React.useContext(AppContext);
  const localizationService = useLocalization();

  const { dtg } = useServices();
  const { login } = useStores();
  let history = useHistory();
  const { event_id } = useParams<EventScreenParams>();

  const siteDomain: string =
    process.env.REACT_APP_ENV === "staging"
      ? "staging.dtg.events"
      : "dtg.events";

  const [dialogDisplayed, setDialogDisplayed] = useState<string>("none");
  const [formDisplayed, setFormDisplayed] = useState<string>("none");
  const [formError, setFormError] = useState<string>();

  const [planners, setPlanners] = useState<UserDetails[]>([]);

  const [thisEvent, setThisEvent] = useState<DTGEvent>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customData, setCustomData] = useState<any>({});
  const [addressVerified, toggleAddressVerified] = useState<boolean>(true);
  interface IMagic {
    url: string;
    token: string;
  }
  const [magicLink, setMagicLink] = useState<IMagic>();
  const [dealer, setDealer] = useState<Dealer>();
  const [charity, setCharity] = useState<Charity>();
  // const [assignedPlanner, setAssignedPlanner] = useState<UserDetails>();
  const [timeZones, setTimeZones] = useState<string[]>();

  const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
  const onAdd = (event: UploadOnAddEvent) => {
    console.log("onAdd: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    console.log("onRemove: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onProgress = (event: UploadOnProgressEvent) => {
    console.log("onProgress: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    console.log("onStatusChange: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const doMagicLink = () => {
    console.log("magicLink", magicLink);
    const { url, token } = magicLink!;
    window.open(`${url}/auto_login/${token}`, "_blank");
  };

  const getRequestData = () => {
    setFormError("");
    setTimeZones(raw_timezones);
    dtg.getPlanners().then((plannersResult) => {
      // const foundPlanners = (plannersResult as DTGFetchResult).result as UserDetails[];
      setPlanners((plannersResult as DTGFetchResult).result as UserDetails[]);
      dtg.getEventToken(event_id).then((tokenResult) => {
        const { token, url } = (tokenResult as DTGFetchResult).result;

        setMagicLink({
          token,
          url,
        });
      });

      dtg.getEvent(event_id).then((ret) => {
        console.log("ret ****", ret);

        const { event, dealer, charity } = (ret as DTGFetchResult).result;

        // const { assigned_planner } = JSON.parse(event?.eligibility_custom_data || "{}");

        // dealer.dealer_type = dealerTypes.find(
        //   (x) => x.value === dealer?.dealer_type
        // );
        // dealer.dealer_region = dealerRegions.find(
        //   (x) => x.value === dealer?.dealer_region
        // );

        charity.address_2 = charity.address_2 || "";

        // setAssignedPlanner(
        //   foundPlanners?.find((x) => x.user_id === assigned_planner)
        // );
        // console.log("event!!!", event.status);

        setThisEvent(new DTGEvent(event));
        setCustomData(JSON.parse(event.event_custom_data || "{}"));
        console.log("customData", JSON.parse(event.event_custom_data || "{}"));
        setDealer(new Dealer(dealer));
        
        setCharity(charity as Charity);
      });
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getRequestData, []);

  const ref: any = React.useRef(null);
  const attendanceRef: any = React.useRef(null);
  const dateRef: any = React.useRef(null);
  const startTimeRef: any = React.useRef(null);
  const endTimeRef: any = React.useRef(null);

  const handleOnWheel = (event: any) => {
    event.stopPropagation();
  };

  React.useEffect(() => {
    ref?.current?.element.addEventListener('wheel', handleOnWheel);
    attendanceRef?.current?.element.addEventListener('wheel', handleOnWheel);
    dateRef?.current?.element.addEventListener('wheel', handleOnWheel);
    startTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);
    endTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);

    return () => {
      ref?.current?.element.removeEventListener('wheel', handleOnWheel);
      attendanceRef?.current?.element.removeEventListener('wheel', handleOnWheel);
      dateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
      startTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
      endTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
    };
  });

  const onSubmitDealer = React.useCallback(
    async (dataItem) => {
      setFormDisplayed("none");

      // dataItem.dealer_type = dataItem.dealer_type?.value;
      // dataItem.dealer_region = dataItem.dealer_region?.value;

      if (!dataItem.confirm_password) {
        delete dataItem.password;
      }

      const call: any = await dtg.updateDealer(dataItem);
      if (call.result?.errors) {
        console.log("[EventDetailScreen]", "Update Dealer Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        console.log("[EventDetailScreen]", "dealer updated");
        history.push('/events');
      }
    },
    [dtg, history]
  );

  const onSubmitCharity = React.useCallback(
    async (dataItem) => {
      setFormDisplayed("none");

      const call: any = await dtg.updateCharity(dataItem);
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Update Charity Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        console.log("[EventDetailScreen]", "charity updated");
        history.push('/events');
      }
    },
    [dtg, history]
  );

  const onSubmitPlanner = React.useCallback(
    async (dataItem) => {
      console.log("dataItem!!!!!!!!", dataItem);
      dtg
        .setEligibleEventRequest(
          thisEvent!.event_request_id!,
          dataItem.assigned_planner.user_id
        )
        .then(() => {
          setDialogDisplayed("none");
          history.push('/events');
        });
    },
    [dtg, history, thisEvent]
  );

  const rejectEligibility = () => {
    dtg.setIneligibleEventRequest(thisEvent!.event_request_id!).then(() => {
      setDialogDisplayed("none");
      history.push('/events');
    });
  };

  const filterTimeZones = (
    filter: FilterDescriptor | CompositeFilterDescriptor
  ) => {
    return filterBy(raw_timezones, filter);
  };

  const filterTZ = (event: DropDownListFilterChangeEvent) => {
    setTimeZones(filterTimeZones(event.filter));
  };

  const formatRange = (start?: Date, end?: Date) => {
    let range = "";
    if (start) {
      range += moment(start).format("h:mm a");
    }
    if (start && end) {
      range += " - ";
    }
    if (end) {
      range += moment(end).format("h:mm a");
    }

    return range;
  };

  // const { file_id } = JSON.parse(
  //   thisEvent?.event_custom_data || "{}"
  // );

  const onSubmitPlan = React.useCallback(
    async (dataItem) => {
      console.log("dataItem!!!!!!!!", dataItem);
      setFormDisplayed("none");

      // format dates properly
      if (dataItem.start_date) {
        dataItem.start_date = moment(dataItem.start_date).format("YYYY-MM-DD");
      }
      if (dataItem.start_time) {
        dataItem.start_time = moment(dataItem.start_time).format("HH:mm:00");
      }
      if (dataItem.end_time) {
        dataItem.end_time = moment(dataItem.end_time).format("HH:mm:00");
      }

      let customData = JSON.parse(thisEvent!.custom_data || "{}");
      customData = {
        ...customData,
        requested_month: dataItem.requested_month,
        requested_date: dataItem.requested_date,
      };

      dataItem.custom_data = JSON.stringify(customData);

      let eventCustomData = JSON.parse(thisEvent!.event_custom_data || "{}");
      eventCustomData = {
        ...eventCustomData,
        centuryParty: dataItem.event_type,
      };

      dataItem.event_custom_data = JSON.stringify(eventCustomData);

      const [selectedFile] = files;
      const rawFile =
        selectedFile && selectedFile.getRawFile && selectedFile.getRawFile();
      // if (rawFile) {
      //     dataItem.event_plan_file = rawFile;
      // }

      const call: any = await dtg.saveEventPlan(
        new DTGEvent(dataItem),
        rawFile
      );
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Update Event Request Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        console.log("[EventDetailScreen]", "Event Request updated");
        history.push('/events');
      }
    },
    [dtg, files, history, thisEvent]
  );

  const onDenyPlan = React.useCallback(
    async (dataItem) => {
      console.log("dataItem!!!!!!!!", dataItem);
      dtg
        .denyPlan(thisEvent!.event_request_id!, dataItem.deny_message)
        .then(() => {
          setDialogDisplayed("none");
          history.push("/events");
        });
    },
    [dtg, history, thisEvent]
  );

  const onSubmitFinalApproval = React.useCallback(
    async () => {
      const call: any = await dtg.submitFinalApproval(
        thisEvent?.event_request_id
      );
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Event Approved Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        console.log("[EventDetailScreen]", "Event Approved");
        history.push("/events");
      }
    },
    [dtg, history, thisEvent]
  );

  const onSubmitReimbursementApproval = React.useCallback(
    async (dataItem) => {
      console.log("DATA ITEM SUBMIT", dataItem);
      if (!dataItem.reimbursement_amount || dataItem.reimbursement_amount <= 0) {
        setFormError("Reimbursement amount is required.");
        return;
      }
      setFormError("");
      const call: any = await dtg.submitReimbursementApproval(
        thisEvent?.event_id,
        dataItem.reimbursement_amount,
      );
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Submit Reimbursement Error");
        setFormError(call.result.errors.join("\n"));
      }
      if (
        Object.keys(call.result).includes("verified") &&
        call.result.verified === false
      ) {
        console.log("success but not verified!!");
        toggleAddressVerified(false);
        // display message and display button to force through
      } else {
        toggleAddressVerified(true);
        console.log("[EventDetailScreen]", "Reimbursement Approved");
        history.push("/events");
      }
    },
    [dtg, history, thisEvent]
  );

  const onSubmitReimbursementApprovalForceAddress = React.useCallback(
    async (dataItem) => {
      const call: any = await dtg.submitForcedReimbursementApproval(
        thisEvent?.event_id,
        dataItem.reimbursement_amount,
      );
      console.log("DONATION FORCED CALL", call);

      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Reimbursement Approved Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        toggleAddressVerified(true);
        console.log("[EventDetailScreen]", "Reimbursement Approved");
        history.push("/events");
      }
    },
    [dtg, history, thisEvent]
  );

  const onSubmitDonationApproval = React.useCallback(
    async (dataItem) => {

      const call: any = await dtg.submitDonationApproval(
        thisEvent?.event_id,
        dataItem.event_summary,
        dataItem.check_amount,
      );

      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Donation Approved Error");
        setFormError(call.result.errors.join("\n"));
      }
      if (
        Object.keys(call.result).includes("verified") &&
        call.result.verified === false
      ) {
        console.log("success but not verified!!");
        toggleAddressVerified(false);
        // display message and display button to force through
      } else {
        toggleAddressVerified(true);
        console.log("[EventDetailScreen]", "Donation Approved");
        history.push("/events");
      }
    },
    [dtg, history, thisEvent]
  );

  const onSubmitDonationApprovalForceAddress = React.useCallback(
    async (dataItem) => {
      const call: any = await dtg.submitForcedDonationApproval(
        thisEvent?.event_id,
        dataItem.event_summary,
        dataItem.check_amount,
      );
      console.log("DONATION FORCED CALL", call);

      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Donation Approved Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        toggleAddressVerified(true);
        console.log("[EventDetailScreen]", "Donation Approved");
        history.push("/events");
      }
    },
    [dtg, history, thisEvent]
  );

  const eventTypes = [
    { text: "No test-drive or walkaround", value: "basic" },
    { text: "Test-Drive", value: "test_drive" },
    { text: "Walkaround", value: "walkaround" },
  ];

  const eventPortalLink = `https://${siteDomain}/`;
  const surveyLink = `https://${siteDomain}/${thisEvent?.pre_reg_hash}`;

  return (
    <div id="Eligibility" className="eligibility-page">
      {dialogDisplayed === "approveEligibility" && (
        <Dialog>
          <h3>Assign a Planner</h3>
          <Form
            onSubmit={onSubmitPlanner}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ width: 250 }}>
                {formError && <Error>{formError}</Error>}
                <Field
                  component={DropDownList}
                  id={"assigned_planner"}
                  name={"assigned_planner"}
                  label={localizationService.toLanguageString(
                    "custom.assigned_planner",
                    "assigned_planner"
                  )}
                  data={planners}
                  dataItemKey="user_id"
                  textField="display_name"
                  required={true}
                />
                <br />
                <DialogActionsBar>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.save",
                      "save"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "denyPlan" && (
        <Dialog>
          <h3>Deny Plan</h3>
          <Form
            onSubmit={onDenyPlan}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                <p>Please enter a message for the Retailer.</p>
                {formError && <Error>{formError}</Error>}
                <Field
                  component={TextArea}
                  id={"deny_message"}
                  name={"deny_message"}
                  label={localizationService.toLanguageString(
                    "custom.deny_plan_reason",
                    "deny_plan_reason"
                  )}
                  required={true}
                />
                <DialogActionsBar>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.deny_plan",
                      "deny_plan"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "approvePlan" && (
        <Dialog>
          <h3>Approve Event</h3>
          <Form
            onSubmit={onSubmitFinalApproval}
            ignoreModified={true}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                <p>
                  Are you sure you want to approve this event? The dealer will
                  be notified immediately.
                </p>
                {formError && <Error>{formError}</Error>}
                <DialogActionsBar>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.approve_event",
                      "approve_event"
                    )}
                  </Button>
                  &nbsp;
                  <Button onClick={() => setDialogDisplayed("none")}>
                    {localizationService.toLanguageString(
                      "custom.cancel",
                      "cancel"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "reimbursement" && (
        <Dialog>
          <h3>Approve Reimbursement</h3>
          <Form
            onSubmit={
              addressVerified
                ? onSubmitReimbursementApproval
                : onSubmitReimbursementApprovalForceAddress
            }
            ignoreModified={true}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                <p>
                  Are you sure you want to approve this reimbursement? The
                  dealer will be notified immediately.
                </p>
                <Field
                  component={NumericTextBox}
                  id={"reimbursement_amount"}
                  childRef={ref}
                  name={"reimbursement_amount"}
                  format="c2"
                  label={localizationService.toLanguageString(
                    "custom.reimbursement_amount",
                    "Reimbursement Amount"
                  )}
                  required={true}
                />
                {!addressVerified && (
                  <>
                    <div className="address_unverified_text">
                      <p>
                        This retailer address could not be verified by USPS.
                        Please see below and confirm that the address is
                        correct. If not, select "Cancel" and "View/Edit
                        Retailer" to edit the address.
                      </p>
                      <p>
                        If the address is correct, press "Confirm Address" to
                        continue the reimbursement process.
                      </p>
                    </div>
                    <div className="dealer_address_cntr">
                      <p>
                        {dealer?.address} {dealer?.address2}
                      </p>
                      <p>
                        {dealer?.city}, {dealer?.state} {dealer?.zip}
                      </p>
                    </div>
                  </>
                )}
                {formError && <Error>{formError}</Error>}
                <DialogActionsBar>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.approve_reimbursement",
                      "Approve Reimbursement"
                    )}
                  </Button>
                  &nbsp;
                  <Button onClick={() => setDialogDisplayed("none")}>
                    {localizationService.toLanguageString(
                      "custom.cancel",
                      "cancel"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "approveDonation" && (
        <Dialog className="approve_donation_dialog">
          <h3>Approve Donation</h3>
          <Form
            onSubmit={
              addressVerified
                ? onSubmitDonationApproval
                : onSubmitDonationApprovalForceAddress
            }
            ignoreModified={true}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                <p>Event Notes (optional)</p>
                <Field
                  component={TextArea}
                  id={"event_summary"}
                  name={"event_summary"}
                  label={localizationService.toLanguageString(
                    "custom.event_summary",
                    "event_summary"
                  )}
                />
                <p>Total Donation Amount:</p>
                <Field
                  component={Input}
                  id={"check_amount"}
                  name={"check_amount"}
                  validator={dollarValidator}
                />
                {!addressVerified && (
                  <>
                    <div className="address_unverified_text">
                      <p>
                        This retailer address could not be verified by USPS.
                        Please see below and confirm that the address is
                        correct. If not, select "Cancel" and "View/Edit
                        Retailer" to edit the address.
                      </p>
                      <p>
                        If the address is correct, press "Confirm Address" to
                        continue the donation process.
                      </p>
                    </div>
                    <div className="dealer_address_cntr">
                      <p>
                        {dealer?.address} {dealer?.address2}
                      </p>
                      <p>
                        {dealer?.city}, {dealer?.state} {dealer?.zip}
                      </p>
                    </div>
                  </>
                )}
                {formError && <Error>{formError}</Error>}
                <DialogActionsBar>
                  {addressVerified ? (
                    <Button
                      primary={true}
                      type={"submit"}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      {localizationService.toLanguageString(
                        "custom.approve_donation",
                        "approve_donation"
                      )}
                    </Button>
                  ) : (
                    <Button
                      primary={true}
                      type={"submit"}
                      onClick={() => { }}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      {localizationService.toLanguageString(
                        "custom.confirm_address",
                        "Confirm Address"
                      )}
                    </Button>
                  )}
                  &nbsp;
                  <Button
                    onClick={() => {
                      toggleAddressVerified(true);
                      setDialogDisplayed("none");
                    }}
                  >
                    {localizationService.toLanguageString(
                      "custom.cancel",
                      "cancel"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "none" && (
        <div className="card-container" style={{ maxWidth: 700 }}>
          <h1 className="card-title">
            {localizationService.toLanguageString(
              "custom.event_details",
              "event_details"
            )}
          </h1>

          <div className="card-component">
            <br />
            <h3>
              Status: {capitalize(thisEvent?.status)}
              {
                // Eligibility buttons
                thisEvent?.status === "new" &&
                login.user?.credentials.hasPermission('admin') && (
                  <p>
                    <Button
                      onClick={() => setDialogDisplayed("approveEligibility")}
                    >
                      Approve Eligibility
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={rejectEligibility}>
                      Reject Eligibility
                    </Button>
                  </p>
                )
              }

              {thisEvent?.event_id && (
                <p>
                  <Button onClick={() => doMagicLink()}>
                    Event Portal Login
                  </Button>
                </p>
              )}
              {thisEvent?.status === "event ended" && (
                <p>
                  <Button onClick={() => setDialogDisplayed("approveDonation")}>
                    Approve Donation
                  </Button>
                </p>
              )}
            </h3>
            <hr />
            {formDisplayed === "none" && thisEvent?.event_id && (
              <>
                <h3>Event Details</h3>{" "}
                {/* <Button onClick={() => setFormDisplayed('eventRequest')}>View/Edit Request</Button> */}
                <p>
                  Name: {thisEvent?.event_name}
                  <br />
                  Event Portal:{" "}
                  <a href={eventPortalLink} target="_blank" rel="noreferrer">
                    {eventPortalLink}
                  </a>
                  <br />
                  Latitude Event ID: {thisEvent.event_id}
                  <br />
                  FFS Event ID: {thisEvent.ffs_event_id}
                  <br />
                  Event Password: {thisEvent.digital_event_code}
                  <br />
                  Event Survey URL:{" "}
                  <a href={surveyLink} target="_blank" rel="noreferrer">
                    {surveyLink}
                  </a>
                  <br />
                  Event Date:{" "}
                  {moment(thisEvent.start_date).format("dddd, MMMM Do")}{" "}
                  {formatRange(thisEvent.start_time, thisEvent.end_time)}
                  <br />
                  Charity: {thisEvent?.charity_partner}
                  <br />
                  Event Address: {`${thisEvent?.address}, ${thisEvent?.city} ${thisEvent?.state} ${thisEvent?.zip_code}`}
                </p>
              </>
            )}

            {formDisplayed === "event" && (
              <Form
                onSubmit={onSubmitPlan}
                initialValues={{
                  ...formValues,
                  ...thisEvent,
                  event_type: thisEvent?.event_type,
                }}
                render={(formRenderProps) => (
                  <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                    <h3>Edit Event Plan</h3>
                    {formError && <Error>{formError}</Error>}
                    <Field
                      id={"event_name"}
                      name={"event_name"}
                      maxLength={25}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.event_name",
                        "event_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      component={DropDownList}
                      id={"event_type"}
                      name={"event_type"}
                      label={localizationService.toLanguageString(
                        "custom.event_type",
                        "event_type"
                      )}
                      data={eventTypes}
                      textField={"text"}
                      valueField={"value"}
                    // data={['basic','test_drive','walkaround']}
                    // required={true}
                    />
                    <Field
                      id={"funding_purpose"}
                      name={"funding_purpose"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.funding_purpose",
                        "funding_purpose"
                      )}
                      // validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"event_plan_file"}
                      name={"event_plan_file"}
                      type={"file"}
                      multiple={false}
                      files={files}
                      onAdd={onAdd}
                      onRemove={onRemove}
                      onProgress={onProgress}
                      onStatusChange={onStatusChange}
                      label={localizationService.toLanguageString(
                        "custom.event_plan_file",
                        "event_plan_file"
                      )}
                      // validator={requiredValidator}
                      component={Upload}
                    />
                    {/* <input
                                            id={'event_plan_file'}
                                            name={'event_plan_file'}
                                            type={'file'}
                                            multiple={false}
                                            // label={localizationService.toLanguageString('custom.event_plan_file', 'event_plan_file')}
                                            onChange={handleFileSelected}
                                            // validator={requiredValidator}
                                            // component={Input}
                                            ref={inputRef}
                                        /> */}
                    <Field
                      component={DropDownList}
                      id={"time_zone"}
                      name={"time_zone"}
                      label={localizationService.toLanguageString(
                        "custom.time_zone",
                        "time_zone"
                      )}
                      data={timeZones}
                      filterable={true}
                      onFilterChange={filterTZ}
                    // required={true}
                    />
                    <Field
                      id={"start_date"}
                      name={"start_date"}
                      label={localizationService.toLanguageString(
                        "custom.event_date",
                        "event_date"
                      )}
                      childRef={dateRef}
                      format="MM/dd/yyyy"
                      min={moment().toDate()}
                      max={moment("2022-11-30").toDate()}
                      component={DatePicker}
                    // required={true}
                    />
                    <Field
                      component={TimePicker}
                      childRef={startTimeRef}
                      id={"start_time"}
                      name={"start_time"}
                      label={localizationService.toLanguageString(
                        "custom.start_time",
                        "start_time"
                      )}
                    // required={true}
                    />
                    <Field
                      component={TimePicker}
                      childRef={endTimeRef}
                      id={"end_time"}
                      name={"end_time"}
                      label={localizationService.toLanguageString(
                        "custom.end_time",
                        "end_time"
                      )}
                    // required={true}
                    />
                    <Field
                      id={"event_location_name"}
                      name={"event_location_name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.event_location_name",
                        "event_location_name"
                      )}
                      // validator={requiredValidator}
                      hint={localizationService.toLanguageString(
                        "custom.event_location_hint",
                        "event_location_hint"
                      )}
                      component={Input}
                    />
                    <Field
                      id={"address"}
                      name={"address"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address",
                        "address"
                      )}
                      // validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"city"}
                      name={"city"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.city",
                        "city"
                      )}
                      // validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"state"}
                      name={"state"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.state",
                        "state"
                      )}
                      // validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"zip_code"}
                      name={"zip_code"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.zip",
                        "zip"
                      )}
                      // validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"location_contact"}
                      name={"location_contact"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.location_contact",
                        "location_contact"
                      )}
                      // validator={requiredValidator}
                      hint={localizationService.toLanguageString(
                        "custom.location_contact_hint",
                        "location_contact_hint"
                      )}
                      component={Input}
                    />
                    <Field
                      id={"location_phone"}
                      name={"location_phone"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.location_phone",
                        "location_phone"
                      )}
                      // required={true}
                      // validator={phoneValidator}
                      // mask={"000-000-0000"}
                      component={MaskedTextBox}
                    />
                    <Field
                      id={"location_email"}
                      name={"location_email"}
                      type={"email"}
                      placeholder={"e.g.: peter@gmail.com"}
                      label={localizationService.toLanguageString(
                        "custom.location_email",
                        "location_email"
                      )}
                      // validator={emailValidator}
                      // required={true}
                      component={Input}
                    />
                    <Field
                      id={"attendance"}
                      name={"attendance"}
                      // type={'input'}
                      childRef={attendanceRef}
                      label={localizationService.toLanguageString(
                        "custom.attendance",
                        "attendance"
                      )}
                      // validator={requiredValidator}
                      component={NumericTextBox}
                    />
                    <hr />
                    <div className={"k-form-buttons"}>
                      <Button
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {localizationService.toLanguageString(
                          "custom.save",
                          "save"
                        )}
                      </Button>

                      <Button onClick={() => setFormDisplayed("none")}>
                        {localizationService.toLanguageString(
                          "custom.cancel",
                          "cancel"
                        )}
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            )}

            {formDisplayed === "none" && (
              <>
                <h3>
                  Retailer{" "}
                  <Button onClick={() => setFormDisplayed("dealer")}>
                    View/Edit Retailer
                  </Button>
                </h3>
                <p>
                  Name: {dealer?.dealer_name}
                  <br />
                  Sales Code: {dealer?.parts_code}
                  <br />
                  Contact: {dealer?.primary_contact}
                  <br />
                  Contact Email: {dealer?.contact_email}
                  <br />
                  Contact Phone: {dealer?.contact_phone}
                </p>
                <p style={{ fontWeight: 700, marginBottom: 0 }}>
                  Check Mailing Address:
                </p>
                <p style={{ marginTop: 0 }}>
                  Address: {dealer?.address}
                  <br />
                  Apt/Suite/Other: {dealer?.address2}
                  <br />
                  City: {dealer?.city}
                  <br />
                  State: {dealer?.state}
                  <br />
                  Zip: {dealer?.zip}
                  <br />
                </p>
              </>
            )}
            {formDisplayed === "dealer" && (
              <Form
                onSubmit={onSubmitDealer}
                initialValues={{
                  ...formValues,
                  ...dealer,
                }}
                render={(formRenderProps) => (
                  <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                    <h3>Edit Retailer11</h3>
                    {formError && <Error>{formError}</Error>}
                    <Field
                      id={"dealer_name"}
                      name={"dealer_name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.dealer_name",
                        "dealer_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                      maxLength={50}
                    />
                    {/* <Field
                      component={DropDownList}
                      id={"dealer_type"}
                      name={"dealer_type"}
                      label={localizationService.toLanguageString(
                        "custom.dealer_type",
                        "dealer_type"
                      )}
                      data={dealerTypes}
                      dataItemKey="value"
                      textField="text"
                      required={true}
                    /> */}
                    <Field
                      id={"parts_code"}
                      name={"parts_code"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.sales_code",
                        "sales_code"
                      )}
                      validator={partsCodeValidator}
                      component={Input}
                      minLength={5}
                      maxLength={5}
                    />
                    {/* <Field
                      id={'dealer_code'}
                      name={'dealer_code'}
                      label={localizationService.toLanguageString('custom.dealer_code', 'dealer_code')}
                      component={Input}
                      validator={dealerCodeValidator}
                    /> */}
                    <Field
                      id={"address"}
                      name={"address"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address",
                        "address"
                      )}
                      validator={address1Validator}
                      component={Input}
                    />
                    <Field
                      id={"address2"}
                      name={"address2"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address2",
                        "address2"
                      )}
                      component={Input}
                    />
                    <Field
                      id={"city"}
                      name={"city"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.city",
                        "city"
                      )}
                      validator={cityValidator}
                      component={Input}
                    />
                    <Field
                      id={"state"}
                      name={"state"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.state",
                        "state"
                      )}
                      validator={stateValidator}
                      component={Input}
                    />
                    <Field
                      id={"zip"}
                      name={"zip"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.zip",
                        "zip"
                      )}
                      validator={zipValidator}
                      component={Input}
                    />
                    <Field
                      id={"phone"}
                      name={"phone"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.dealershipPhone",
                        "dealershipPhone"
                      )}
                      validator={phoneValidator}
                      component={Input}
                    />
                    {/* <Field
                      component={DropDownList}
                      id={"dealer_region"}
                      name={"dealer_region"}
                      label={localizationService.toLanguageString(
                        "custom.dealer_region",
                        "dealer_region"
                      )}
                      data={dealerRegions}
                      dataItemKey="value"
                      textField="text"
                      validator={requiredValidator}
                      required={true}
                    /> */}
                    <Field
                      id={"primary_contact"}
                      name={"primary_contact"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.primary_contact",
                        "primary_contact"
                      )}
                      validator={requiredValidator}
                      component={Input}
                      maxLength={50}
                    />
                    <Field
                      id={"contact_phone"}
                      name={"contact_phone"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_phone",
                        "contact_phone"
                      )}
                      validator={phoneValidator}
                      component={Input}
                    />
                    <Field
                      id={"contact_email"}
                      name={"contact_email"}
                      type={"email"}
                      autoComplete="username"
                      placeholder={"e.g.: peter@gmail.com"}
                      label={localizationService.toLanguageString(
                        "custom.contactEmail",
                        "Email"
                      )}
                      validator={emailValidator}
                      required={true}
                      component={Input}
                    />
                    <Field
                      id={"password"}
                      name={"password"}
                      autoComplete="new-password"
                      type={"password"}
                      label={localizationService.toLanguageString(
                        "custom.password",
                        "Password"
                      )}
                      validator={newPasswordValidator}
                      component={Input}
                    />
                    <Field
                      id={"confirm_password"}
                      name={"confirm_password"}
                      type={"password"}
                      autoComplete="new-password"
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.confirmPassword",
                        "Confirm Password"
                      )}
                      validator={passwordMatchValidator}
                      component={Input}
                    />
                    <hr />
                    <div className={"card-buttons"}>
                      <Button
                        type={"button"}
                        onClick={() => setFormDisplayed("none")}
                      >
                        {localizationService.toLanguageString(
                          "custom.cancel",
                          "cancel"
                        )}
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {localizationService.toLanguageString(
                          "custom.update",
                          "update"
                        )}
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            )}

            {formDisplayed === "none" && (
              <>
                <h3>
                  Charity{" "}
                  <Button onClick={() => setFormDisplayed("charity")}>
                    View/Edit Charity
                  </Button>
                </h3>
                <p>
                  Name: {charity?.name}
                  <br />
                  Tax ID: {charity?.tax_id}
                </p>
              </>
            )}
            {console.log("EVENT ***", thisEvent)}
            {formDisplayed === "none" && thisEvent?.status && (
              <>
                <h3>
                  Donation Details{" "}
                </h3>

                <p>
                  Donation Amount:{" "}
                  {
                    thisEvent.check_amount_pretty
                  }
                  <br />
                </p>
              </>
            )}
            {formDisplayed === "charity" && (
              <Form
                onSubmit={onSubmitCharity}
                initialValues={{
                  ...formValues,
                  ...charity,
                }}
                render={(formRenderProps) => (
                  <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                    <h3>Edit Charity</h3>
                    {formError && <Error>{formError}</Error>}
                    <Field
                      id={"name"}
                      name={"name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.charity_name",
                        "charity_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    {/* <Field
                      id={"address_1"}
                      name={"address_1"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address_1",
                        "address_1"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"address_2"}
                      name={"address_2"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address_2",
                        "address_2"
                      )}
                      component={Input}
                    />
                    <Field
                      id={"city"}
                      name={"city"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.city",
                        "city"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"state"}
                      name={"state"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.state",
                        "state"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"postal_code"}
                      name={"postal_code"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.zip",
                        "zip"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    /> */}
                    <Field
                      id={"contact_first_name"}
                      name={"contact_first_name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_first_name",
                        "contact_first_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"contact_last_name"}
                      name={"contact_last_name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_last_name",
                        "contact_last_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"contact_phone_number"}
                      name={"contact_phone_number"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_phone_number",
                        "contact_phone_number"
                      )}
                      required={true}
                      validator={phoneValidator}
                      mask="000-000-0000"
                      component={MaskedTextBox}
                    />
                    <Field
                      id={"contact_email"}
                      name={"contact_email"}
                      type={"email"}
                      placeholder={"e.g.: peter@gmail.com"}
                      label={localizationService.toLanguageString(
                        "custom.contactEmail",
                        "Email"
                      )}
                      validator={emailValidator}
                      required={true}
                      component={Input}
                    />
                    {/* <Field
                      id={"description"}
                      name={"description"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.description",
                        "description"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    /> */}
                    <Field
                      id={"website"}
                      name={"website"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.website",
                        "website"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"tax_id"}
                      name={"tax_id"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.tax_id",
                        "tax_id"
                      )}
                      validator={requiredValidator}
                      mask="00-0000000"
                      component={MaskedTextBox}
                    />
                    <hr />
                    <div className={"card-buttons"}>
                      <Button
                        type={"button"}
                        onClick={() => setFormDisplayed("none")}
                      >
                        {localizationService.toLanguageString(
                          "custom.cancel",
                          "cancel"
                        )}
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {localizationService.toLanguageString(
                          "custom.update",
                          "update"
                        )}
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetailScreen;
