import moment from 'moment';
// import capitalize from 'src/utils/capitalize';
import { hydrateMobX } from './helpMethods';

const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});  

export class HydratedStore implements IHydratedStore {
  STORAGE_ID: string = '';
  hydrate = async () => this.STORAGE_ID && await hydrateMobX(this.STORAGE_ID, this);

  constructor(storageId: string) {
    this.STORAGE_ID = storageId;
  }
}

export class FormattedGridData {
  data: any;
  formatFunction: Function;

  constructor(data: any, formatFunction: (data: any) => string) {
    this.data = data;
    this.formatFunction = formatFunction;
  }

  toString() {
    return this.formatFunction(this.data);
  }
}

export class UserDetails {
  user_id: number;
  // user_role: UserRole;
  email: string;
  display_name: string;
  active: boolean;
  // dtg_user: boolean;
  activation_partner_id: number[];
  user_role_list: UserRole[];

  constructor(data: any) {
    this.user_id = data.user_id;
    // this.user_role = data.user_role;
    this.email = data.email;
    this.display_name = data.display_name;
    this.active = data.active;
    // this.dtg_user = data.dtg_user;
    this.activation_partner_id = String(data.activation_partner_id || '').split(',').map(Number);
    this.user_role_list = String(data.user_role_list || '').split(',').map(role => role as UserRole);
  }

  hasPermission(permission: string) {
    console.log('hasPermission', this.user_role_list.includes(permission as UserRole), permission, this.user_role_list);
    return this.user_role_list.includes(permission as UserRole);
  }
}

export class User {
  credentials: UserDetails;
  token: string;

  constructor(user: any) {
    this.credentials = new UserDetails(user.credentials);
    this.token = user.token;
  }
}

export class Dealer {
  dealer_id: number;
  dealer_name: string;
  dealer_type: DealerType;
  parts_code: string;
  dealer_code: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  dealer_region: DealerRegion;
  primary_contact: string;
  contact_email: string;
  contact_phone: string;
  active: boolean;
  attended_training: boolean;
  training_attendee_name: string;
  training_date: Date;
  century_approved: string;
  created_timestamp: Date;
  updated_timestamp: Date;

  constructor(dealer: any) {
    this.dealer_id = dealer.dealer_id;
    this.dealer_name = dealer.dealer_name;
    this.dealer_type = dealer.dealer_type;
    this.parts_code = dealer.parts_code;
    this.dealer_code = dealer.dealer_code;
    this.address = dealer.address;
    this.address2 = dealer.address2;
    this.city = dealer.city;
    this.state = dealer.state;
    this.zip = dealer.zip;
    this.phone = dealer.phone;
    this.dealer_region = dealer.dealer_region;
    this.primary_contact = dealer.primary_contact;
    this.contact_email = dealer.contact_email;
    this.contact_phone = dealer.contact_phone;
    this.active = dealer.active === 1; // convert to boolean
    this.attended_training = dealer.attended_training;
    this.training_attendee_name = dealer.training_attendee_name;
    this.training_date = moment(dealer.training_date).toDate();
    this.century_approved = dealer.century_approved;
    this.created_timestamp = moment(dealer.created_timestamp).toDate(); // convert to date
    this.updated_timestamp = moment(dealer.updated_timestamp).toDate(); // convert to date
  }

  public get activeGridCell(): any {
    return this.active ? 'Active' : 'Inactive';
  }
}

const eventTypes = [
  { text: 'No test-drive or walkaround', value: 'basic', export: 2 },
  { text: 'Test-Drive', value: 'test_drive', export: 1 },
  { text: 'Walkaround', value: 'walkaround', export: 3 },
];

export class DTGEvent {
  event_id?: number;
  event_request_id?: number;
  event_name?: string;
  event_series_id?: number;
  bonus_event?: boolean;
  season_id: number;
  dealer_id: number;
  dealer_name: string;
  start_date?: Date;
  end_date?: Date;
  start_time?: Date;
  end_time?: Date;
  time_zone?: string;
  ffs_event_id?: number;
  drive_event_id?: number;
  training_completed?: Date;
  training_completed_by?: string;
  vincent_code?: string;
  vincent_value?: number;
  pre_reg_hash?: string;
  cancelled?: boolean;
  ended?: boolean;
  check_data_processed?: Date;
  dealer_reminder_email_sent?: Date;
  charity_partner_id?: number;
  charity_partner?: string;
  event_location?: string;
  event_location_name?: string;
  address?: string;
  city?: string;
  zip_code?: string;
  state?: string;
  digital_event_code?: string;
  logged_in?: Date;
  spreadsheet_data?: string;
  invalid_address?: boolean;
  edit_log?: string;
  status?: DTGEventStatus;
  created_by_user_id?: number;
  created_timestamp: Date;
  updated_timestamp: Date;
  location_contact?: string;
  location_phone?: string;
  location_email?: string;
  funding_purpose?: string;
  attendance?: number;
  rejected?: boolean;
  check_location?: string;
  check_payable?: string;
  check_attention?: string;
  check_address?: string;
  check_address_2?: string;
  check_city?: string;
  check_state?: string;
  check_zip_code?: string;
  display_name?: string;
  custom_data?: string;
  eligibility_custom_data?: string;
  event_custom_data?: string;
  ffs_lincoln_campaign?: string;
  ffs_lincoln_sequence?: string;
  check_amount?: number;

  constructor(event: any) {
    this.event_id = event.event_id;
    this.event_request_id = event.event_request_id;
    this.event_name = event.event_name;
    this.event_series_id = event.event_series_id;
    this.bonus_event = event.bonus_event;
    this.season_id = event.season_id;
    this.dealer_id = event.dealer_id;
    this.dealer_name = event.dealer_name;
    this.start_date = event.start_date ? moment(event.start_date).toDate() : undefined;
    this.end_date = event.end_date ? moment(event.end_date).toDate() : undefined;
    this.start_time = event.start_time ? moment(event.start_time, 'HH:mm:ss').toDate() : undefined;
    this.end_time = event.end_time ? moment(event.end_time, 'HH:mm:ss').toDate() : undefined;
    this.time_zone = event.time_zone;
    this.ffs_event_id = event.ffs_event_id;
    this.drive_event_id = event.drive_event_id;
    this.training_completed = event.training_completed ? moment(event.training_completed).toDate() : undefined;
    this.training_completed_by = event.training_completed_by;
    this.vincent_code = event.vincent_code;
    this.vincent_value = event.vincent_value;
    this.pre_reg_hash = event.pre_reg_hash;
    this.cancelled = event.cancelled === '1' ? true : false;
    this.ended = event.ended;
    this.check_data_processed = event.check_data_processed ? moment(event.check_data_processed).toDate() : undefined;
    this.dealer_reminder_email_sent = event.dealer_reminder_email_sent ? moment(event.dealer_reminder_email_sent).toDate() : undefined;
    this.charity_partner_id = event.charity_partner_id;
    this.charity_partner = event.charity_partner;
    this.event_location = event.event_location;
    this.event_location_name = event.event_location_name;
    this.address = event.address;
    this.city = event.city;
    this.zip_code = event.zip_code || '';
    this.state = event.state;
    this.digital_event_code = event.digital_event_code;
    this.logged_in = event.logged_in ? moment(event.logged_in).toDate() : undefined;
    this.spreadsheet_data = event.spreadsheet_data;
    this.invalid_address = event.invalid_address;
    this.edit_log = event.edit_log;
    this.status = event.status;
    this.created_by_user_id = event.created_by_user_id;
    this.created_timestamp = moment(event.created_timestamp).toDate();
    this.updated_timestamp = moment(event.updated_timestamp).toDate();
    this.location_contact = event.location_contact;
    this.location_phone = event.location_phone;
    this.location_email = event.location_email;
    this.funding_purpose = event.funding_purpose;
    this.attendance = event.attendance;
    this.rejected = event.rejected;
    this.check_location = event.check_location;
    this.check_payable = event.check_payable;
    this.check_attention = event.check_attention;
    this.check_address = event.check_address;
    this.check_address_2 = event.check_address_2;
    this.check_city = event.check_city;
    this.check_state = event.check_state;
    this.check_zip_code = event.check_zip_code || '';
    this.display_name = event.display_name;
    this.custom_data = event.custom_data;
    this.eligibility_custom_data = event.eligibility_custom_data;
    this.event_custom_data = event.event_custom_data;
    this.ffs_lincoln_campaign = event.ffs_lincoln_campaign;
    this.ffs_lincoln_sequence = event.ffs_lincoln_sequence;
    this.check_amount = event.check_amount;
  }

  public get start_date_export(): string {
    return moment(this.start_date).format('M/D/YY')
  }

  public get end_date_export(): string {
    return moment(this.end_date).format('M/D/YY')
  }

  public get market_area(): string {
    // const marketArea = {
    //   '1': 'C',
    //   '2': 'E',
    //   '3': 'G',
    //   '4': 'S',
    //   '5': 'W',
    // }
    return '';
  }

  public get audience_type(): string {
    return 'G';
  }

  public get category(): string {
    return 'Deal';
  }

  public get language_option(): string {
    return 'N';
  }

  public get indoor_outdoor(): string {
    return '';
  }

  public get test_drive(): number {
    // 1 = Yes, 2 = No, 3 = Walkaround
    const { centuryParty } = JSON.parse(this.event_custom_data || '{}');
    if (centuryParty) {
      return eventTypes.find(x => x.value === centuryParty)?.export || 2;
    } else {
      return 2;
    }
  }

  public get test_drive_full(): string {
    const { centuryParty } = JSON.parse(this.event_custom_data || '{}');
    if (centuryParty) {
      const thisType = eventTypes.find(x => x.value === centuryParty)?.export;
      return thisType === 1 ? 'Test Drive Event' : 'Non-Test Drive Event';
    } else {
      return 'Non-Test Drive Event';
    }
  }

  public get regional(): string {
    return 'Nat';
  }

  public get lincoln_event_partner(): number {
    return 2;
  }

  public get audience_type_full(): string {
    return 'General';
  }

  public get category_long(): string {
    return 'Dealer Support';
  }

  public get regional_full(): string {
    return 'National';
  }

  public get requested_month(): string | undefined {
    const { requested_month } = JSON.parse(this.eligibility_custom_data || '{}');
    return requested_month;
  }

  public get requested_date(): Date | undefined {
    // const { requested_date } = JSON.parse(this.eligibility_custom_data || '{}');
    // return requested_date ? moment(requested_date).toDate() : undefined;
    return this.start_date;
  }

  public get requested_date_formatted(): string | undefined {
    // const { requested_date } = JSON.parse(this.eligibility_custom_data || '{}');
    // return requested_date ? moment(requested_date).format('dddd, MMMM Do') : undefined;
    return this.start_date ? moment(this.start_date).format('dddd, MMMM Do') : undefined;
  }

  public get event_type(): any {
    const { centuryParty } = JSON.parse(this.event_custom_data || '{}');
    if (centuryParty) {
      return eventTypes.find(x => x.value === centuryParty)
    } else {
      return undefined;
    }
  }

  public get event_type_display(): string | undefined {
    const { centuryParty } = JSON.parse(this.event_custom_data || '{}');
    if (centuryParty) {
      const type = eventTypes.find(x => x.value === centuryParty);
      return type?.text;
    } else {
      return undefined;
    }
  }

  public get json(): string {
    const event = {
      ...this,
      // override values
      start_date: this.start_date ? moment(this.start_date).format('YYYY-MM-DD') : null,
      end_date: this.end_date ? moment(this.end_date).format('YYYY-MM-DD') : null,
      start_time: this.start_time ? moment(this.start_time).format('HH:mm:00') : null,
      end_time: this.end_time ? moment(this.end_time).format('HH:mm:00') : null,
    };

    return JSON.stringify(event);
  }

  // public get start_date_string(): string|undefined {
  //   return this.start_date ? moment(this.start_date).format('YYYY-MM-DD') : undefined;
  // }

  // public get start_time_string(): string|undefined {
  //   return this.start_time ? moment(this.start_time).format('HH:mm:ss') : undefined;
  // }

  // public get end_date_string(): string|undefined {
  //   return this.end_date ? moment(this.end_date).format('YYYY-MM-DD') : undefined;
  // }

  // public get end_time_string(): string|undefined {
  //   return this.end_time ? moment(this.end_time).format('HH:mm:ss') : undefined;
  // }

  public get status_pretty(): string | undefined {
    // return capitalize(this.status);
    const words = this.status?.split(" ");
    if (!words) {
      return '';
    }
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  public get check_amount_pretty(): string | undefined {
    if (this.check_amount) {
      return dollarFormatter.format(this.check_amount);
    }
    return '';
  }

  public get sort_status(): number {
    switch (this.status) {
      case 'new': // dealer has submitted charity eligibility
        return 0;
      case 'eligible': // PHQ has approved charity eligibility
        return 1;
      case 'planning': // dealer is planning event
        return 2;
      case 'revise': // dealer needs to revise event plan
        return 3;
      case 'review': // PHQ needs to review event plan
        return 3.25;
      case 'approved': // event plan approved, event has not happened yet
        return 4;
      case 'event ending': // event has ended, need to approve donation
        return 5;
      case 'event ended': // event has ended, need to approve donation
        return 5.5;
      case 'donation requested': // donation requested by PHQ
        return 6;
      case 'donation approved': // donation approved by Adrienne, dealer needs to submit reimbursement plan
        return 7;
      case 'reimbursement submitted': // reimbursement requested by dealer, needs to be approved by PHQ
        return 8;
      case 'reimbursement requested': // reimbursement requested by PHQ, needs to be approved by Adrienne
        return 9;
      case 'reimbursement approved': // reimbursement approved by Adrienne
        return 10;
      case 'ineligible': // charity was not eligible
        return 100;
      case 'rejected': // event is rejected
        return 101;
      case 'cancelled': // event is cancelled
        return 102;
      default:
        return 999;
    }
  }

  public get sort_date(): Date {
    const { requested_date, requested_month } = JSON.parse(this.eligibility_custom_data || '{}');
    let dateValue: Date;
    if (this.start_date) {
      dateValue = this.start_date;
    } else if (requested_date) {
      dateValue = moment(requested_date).toDate();
    } else {
      // TODO: hard-coded to current year, obviously won't work all the time
      dateValue = moment(`${requested_month} 1 ${moment().year()}`).toDate();
    }

    return dateValue;
  }
}

export class QueuedCheck {
  check_queue_id: number;
  status: string;
  ffs_event_id?: number;
  check_type: string;
  check_amount: number;
  check_approved?: Date;
  start_date?: Date;
  event_name?: string;
  event_id?: number;
  custom_data?: any;
  dealer_name?: string;
  event_summary?: string;
  dealer_id?: number;
  season_id?: number;
  event_series_id?: number;
  created_timestamp: Date;
  updated_timestamp: Date;

  constructor(check: any) {
    this.custom_data = JSON.parse(check.custom_data || '{}');

    this.check_queue_id = check.check_queue_id;
    this.ffs_event_id = check.ffs_event_id;
    this.check_approved = check.check_approved ? moment(check.check_approved).toDate() : undefined;
    this.check_type = this.custom_data.check_type || check.check_type;
    this.check_amount = check.check_amount;
    this.start_date = this.custom_data.event_date || check.start_date;
    this.event_name = this.custom_data.event_name || check.event_name;
    this.event_summary = JSON.parse(check.custom_data || '{}').event_summary;
    this.dealer_name = this.custom_data.dealer_name || check.dealer_name;
    this.dealer_id = check.dealer_id;
    this.season_id = check.season_id;
    this.event_series_id = check.event_series_id;
    this.event_id = check.event_id;
    this.created_timestamp = moment(check.created_timestamp).toDate();
    this.updated_timestamp = moment(check.updated_timestamp).toDate();
    this.status = this.check_approved ? 'Approved' : 'Pending';
  }

  public get sort_status(): number {
    switch (this.status) {
      case 'Pending':
        return 0;
      case 'Approved':
        return 1;
      default:
        return 999;
    }
  }

  public get check_type_pretty(): string | undefined {
    // return capitalize(this.check_type);
    const words = this.check_type?.split("_");
    if (!this.check_type) {
      return '';
    }

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  public get check_amount_pretty(): string | undefined {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(this.check_amount);
  }

  // public get sort_status():number {
  //   switch (this.status) {
  //       case 'new': // dealer has submitted charity eligibility
  //         return 0;
  //       case 'eligible': // PHQ has approved charity eligibility
  //         return 1;
  //       case 'planning': // dealer is planning event
  //         return 2;
  //       case 'revise': // dealer needs to revise event plan
  //         return 3;
  //       case 'review': // PHQ needs to review event plan
  //         return 3.25;
  //       case 'approved': // event plan approved, event has not happened yet
  //         return 4;
  //       case 'event ending': // event has ended, need to approve donation
  //         return 5;
  //       case 'event ended': // event has ended, need to approve donation
  //         return 5.5;
  //       case 'donation requested': // donation requested by PHQ
  //         return 6;
  //       case 'donation approved': // donation approved by Adrienne, dealer needs to submit reimbursement plan
  //         return 7;
  //       case 'reimbursement submitted': // reimbursement requested by dealer, needs to be approved by PHQ
  //         return 8;
  //       case 'reimbursement requested': // reimbursement requested by PHQ, needs to be approved by Adrienne
  //         return 9;
  //       case 'reimbursement approved': // reimbursement approved by Adrienne
  //         return 10;
  //       case 'ineligible': // charity was not eligible
  //         return 100;
  //       case 'rejected': // event is rejected
  //         return 101;
  //       case 'cancelled': // event is cancelled
  //         return 102;
  //       default:
  //         return 999;
  //   }
  // }
}
